import AES from 'crypto-js/aes'
import Utf8 from 'crypto-js/enc-utf8'
import Pkcs7 from 'crypto-js/pad-pkcs7'

export function Encrypt(data) {
	const jsonString = JSON.stringify(data)
	const key = Utf8.parse('MQ8wDQYDVQQHDAZN')
	const iv = Utf8.parse('MQ8wDQYDVQQHDAZN')
	const encrypted = AES.encrypt(Utf8.parse(jsonString), key, {
		keySize: 128 / 8,
		iv: iv,
		padding: Pkcs7
	})

	return encodeURIComponent(encrypted)
}

export function EncryptNew(data) {
	const key = Utf8.parse('MQ8wDQYDVQQHDAZN')
	const iv = Utf8.parse('MQ8wDQYDVQQHDAZN')
	const encrypted = AES.encrypt(Utf8.parse(data), key, {
		keySize: 128 / 8,
		iv: iv,
		padding: Pkcs7
	})

	return encodeURIComponent(encrypted)
}

export function decryptData(data) {
	const dataValue = decodeURIComponent(data)
	const key = Utf8.parse('MQ8wDQYDVQQHDAZN')
	const iv = Utf8.parse('MQ8wDQYDVQQHDAZN')

	const decrypted = AES.decrypt(dataValue, key, {
		keySize: 128 / 8,
		iv: iv,
		padding: Pkcs7
	})

	return JSON.parse(decrypted.toString(Utf8))
}

export function decryptDataNew(data) {
	const dataValue = decodeURIComponent(data)
	const key = Utf8.parse('MQ8wDQYDVQQHDAZN')
	const iv = Utf8.parse('MQ8wDQYDVQQHDAZN')

	const decrypted = AES.decrypt(dataValue, key, {
		keySize: 128 / 8,
		iv: iv,
		padding: Pkcs7
	})

	return decrypted.toString(Utf8)
}


import useAppModel from '../contexts/AppModel'
import Modal from './Modal'
import Spinner from '../common/Spinner'

const FullPageLoader = () => {
	const isLoading = useAppModel(s => s.isLoading)
	const loadingText = useAppModel(s => s.text)

	if (! isLoading) {
		return null
	}

	return (
		<Modal open={true} onClose={() => true}>
			<div className="spinner-container">
				<div><Spinner /></div>
				<div><strong>{loadingText}</strong></div>
			</div>
		</Modal>
	)
}
export default FullPageLoader

function FuellingCard({ totalVol, totalAmt, fuelBy, fuelByValue, price }) {
	return (
		<div className="display-container">
			<div className="display-card">
				<div className="icon-block">
					<i className="icon volume" />
					<span>Litres</span>
				</div>
				<div className="text-block">
					<strong>{totalVol.toFixed(2) || '0.00'}</strong>
				</div>
			</div>

			<div className="display-card">
				<div className="icon-block">
					<i className="icon amount" />
					<span>Rupees</span>
				</div>
				<div className="text-block">
					<strong>{totalAmt.toFixed(2) || '0.00'}</strong>
				</div>
			</div>

			{fuelBy !== 'topup' && (
				<div className="display-card">
					<div className="icon-block">
						<i className="icon nozzle" />
						<span>Preset {fuelBy === 'Volume' ? '(Ltr.)' : '(Rs.)'}</span>
					</div>
					<div className="text-block">
						<strong>{parseFloat(fuelByValue).toFixed(2)}</strong>
					</div>
				</div>
			)}

			<div className="display-card">
				<div className="icon-block">
					<i className="icon amount" />
					<span>Rs./Litre</span>
				</div>
				<div className="text-block">
					<strong>{price}</strong>
				</div>
			</div>
		</div>
	)
}

export default FuellingCard

import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import routePath from '../route'
import useAlertModel from '../contexts/AlertModel'
import AuthContext from '../contexts/authContext'
import FuelContext from '../contexts/fuelContext'
import useLogService from '../services/useLogService'

const style = { cursor: 'pointer', position: 'fixed', bottom: '10px', right: '10px', zIndex: 9, background: 'red', color: 'white', borderRadius: '50%', padding: '10px', width: '50px', height: '50px' }

const CancelCurrenttTxn = () => {
	const history = useHistory()
	const doAlert = useAlertModel(s => s.doAlert)
	const { authState: { userId, }, } = useContext(AuthContext)
	const {
		fuelState: {
			keepAlive, currentStep,
			currentTxn: {
				fuelinvoiceData: { TxnStartTime, totalVol, } = {},
				fuelInputes: { MDUSelectedOrder, selectedType, mopValue, pumpValue, nozzleValue,  } = {},
			} = {},
		} = {},
		resetCurrentTxn,
	} = useContext(FuelContext)
	const log = useLogService()

	const handleProcessTxn = async () => history.push(routePath.fuelSale.path)

	const closeTxn = () => {
		log({
			APIURL: 'cancel_txn',
			type: 'error',
			level: 'high',
			description: JSON.stringify({
				app: { currentStep, userId, },
				tender: { mopValue,  },
				fuel: { TxnStartTime, pumpValue, nozzleValue, totalVol, selectedType, },
			}),
		})
		resetCurrentTxn()
	}

	const showAlert = () => {
		doAlert({
			title: 'Cancel current transaction',
			message: `Order: ${MDUSelectedOrder} (${selectedType})`,
			buttons: [
				{ label: `Continue to ${currentStep}`, onClick: handleProcessTxn },
				{ label: 'Close', onClick: closeTxn },
			]
		})
	}

	return (
		<div onClick={showAlert} style={{ ...style, display: keepAlive ? 'block' : 'none', }} className="btn btn-red">X</div>
	)
}

export default CancelCurrenttTxn

import dayjs from 'dayjs'
import { PRODUCT_TYPE, FUEL_VOLUME_UNIT } from '../constants'
import { CURRENT_TXN } from '../constants'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

export function transformTCFAIC(inptString) {
	const strArr = inptString.split('|')
	const resObj = {}
	const prompt = {}

	strArr.forEach((item) => {
		const config = item.split(':')
		if (config.length === 2 && config[1]) {
			const key = config[0]
			const val = config[1]
			if (key.startsWith('P')) {
				prompt[key] = val
			} else if (key === 'ODRD') {
				const odrdVal = val.split(";")
				if (odrdVal.length === 4) {
					const minVal = parseInt(odrdVal[2].substring(1, odrdVal[2].length), 10)
					const maxVal = parseInt(odrdVal[3].substring(1, odrdVal[3].length), 10)
					prompt[key] = [minVal, maxVal]
				}
			}

			if (key === 'TOTL') {
				resObj[key] = parseFloat(val)
			} else {
				resObj[key] = val
			}
		}
	})

	return { resObj, prompt }
}

export const alwaysFourDigit = input => `${input}`.padStart(4, '0')

export function isURL(input) {
	const rjx = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-zA-Z0-9](.*)$/
	return rjx.test(input) || input[0] === "/"
}

export function FuelingStatusMsg({ status }) {
	switch (status) {
		case 'Starting':
			return <h1 className="fueling-title">Fueling Started</h1>
		case 'Fuelling':
			return <h1 className="fueling-title">Fueling</h1>
		case 'Fuelling_paused':
			return <h1 className="fueling-title">Fueling Paused</h1>
		case 'Fuelling_terminated':
			return <h1 className="fueling-title">Fueling Terminated</h1>
		case 'Idle':
			return <h1 className="fueling-title">Nozzle is Idle</h1>
		case 'Finished':
			return <h1 className="fueling-title text-success">Fueling Completed</h1>
		case 'Error':
			return <h1 className="fueling-title text-danger">Error In Transaction</h1>
		case 'Void':
			return <h1 className="fueling-title text-danger">Void Transaction</h1>
		case 'PreAuthorized':
		default:
			return <h1 className="fueling-title">Please Begin Fuelling</h1>
	}
}

export function trxnSticker(input) {
	let returnVal = PRODUCT_TYPE[`${input}`]
	if (returnVal === 'Diesel') {
		return 'blue'
	}
	if (returnVal === 'Petrol') {
		return 'green'
	}
	if (returnVal === 'ALPG') {
		return 'red'
	}
}

export function clubArray(arrA, arrB) {
	const returnObj = {}
	if (Array.isArray(arrA) && Array.isArray(arrB)) {
		arrA.forEach((itemA, index) => {
			returnObj[itemA] = arrB[index]
		})
	}
	return returnObj
}

export function getStatusCssClass(status) {
	if (status === 'Starting' || status === 'Starting_paused' || status === 'Starting_terminated') {
		return { className: 'red', }
	}
	if (status === 'PreAuthorized') {
		return { className: 'green', }
	}

	if (status === 'Unavailable' || status === 'Error' || status === 'Closed') {
		return { className: 'black', }
	}
	if (status === 'Fuelling' || status === 'Fuelling_paused' || status === 'Fuelling_terminated') {
		return { className: 'blue', }
	}
	if (status === 'Unavailable_and_calling' || status === 'Calling') {
		return { className: 'yellow', }
	}
	return { className: '', }
}

export function transformProductIdWiseFpList(data) {
	const lstFpIds = {}
	data.lstFpIds.forEach((item) => {
		if (lstFpIds[item.FpId]) {
			lstFpIds[item.FpId].NozzleId.push(item.NozzleId)
		} else {
			lstFpIds[item.FpId] = { ...item, NozzleId: [item.NozzleId], }
		}
	})

	return { ...data, lstFpIds: Object.values(lstFpIds) }
}

export function generateTxnObj(data, fuelinvoiceData) {
	const tempCurrentTxn = { ...JSON.parse(JSON.stringify(CURRENT_TXN)) }

	tempCurrentTxn.currentTxn.TempID = data.Id //to be checked
	tempCurrentTxn.currentTxn.fuelInputes.selectedType = data.GradeName
	tempCurrentTxn.currentTxn.fuelInputes.fuelType = data.GradeName
	tempCurrentTxn.currentTxn.fuelInputes.pumpValue = data.FPId
	tempCurrentTxn.currentTxn.fuelInputes.loyalty = false
	tempCurrentTxn.currentTxn.fuelInputes.mopValue = '1'
	tempCurrentTxn.currentTxn.fuelInputes.fuelBy = FUEL_VOLUME_UNIT.money
	tempCurrentTxn.currentTxn.fuelInputes.fuelByValue = data.Money
	tempCurrentTxn.currentTxn.fuelInputes.pumpMode = 'Quick Mode'
	tempCurrentTxn.currentTxn.fuelinvoiceData.TxnStartTime = dayjs(data.FuelStartTime, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YYYY HH:mm:ss')
	tempCurrentTxn.currentTxn.fuelinvoiceData.totalAmt = data.Money
	tempCurrentTxn.currentTxn.fuelinvoiceData.totalVol = data.Vol
	tempCurrentTxn.currentTxn.fuelinvoiceData.saleOk.TransSeqNo = data.TransSeqNo
	tempCurrentTxn.currentTxn.fuelinvoiceData.saleOk.StartTotalizer = data.StartTotalizer
	tempCurrentTxn.currentTxn.fuelinvoiceData.saleOk.EndTotalizer = data.EndTotalizer
	tempCurrentTxn.currentTxn.fuelinvoiceData.saleOk.FuelStartTime = data.FuelStartTime
	tempCurrentTxn.currentTxn.fuelinvoiceData.saleOk.FuelEndTime = data.FuelEndTime
	tempCurrentTxn.currentTxn.fuelinvoiceData.saleOk.FpGradeOptionNo = data.FpGradeOptionNo
	tempCurrentTxn.currentTxn.fuelinvoiceData.txnStatus = data.Money > 0 ? 'COMPLETED' : 'VOIDED'
	tempCurrentTxn.currentTxn.fuelingStarted = true
	tempCurrentTxn.currentTxn.fuelinvoiceData = {
		...tempCurrentTxn.currentTxn.fuelinvoiceData,
		...fuelinvoiceData,
	}
	tempCurrentTxn.currentTxn.currentTxnPrice = data.UnitPrice

	return tempCurrentTxn
}

export const strMask = (input, pre = 2, post = 2) => input.substr(0, pre) + 'X'.repeat(input.length - 4) + input.substr(-post)

import { useEffect } from 'react'
import useAlertModel from '../contexts/AlertModel'
import first from 'lodash/first'
import { confirmAlert } from 'react-confirm-alert'

function ConfirmBox() {
	const alertQueue = useAlertModel(s => s.alertQueue)
	const popAlert = useAlertModel(s => s.popAlert)

	useEffect(() => {
		const alertObj = first(alertQueue)
		if (alertObj) {
			confirmAlert({
				closeOnEscape: false,
				closeOnClickOutside: false,
				...alertObj,
				afterClose: () => popAlert(),
			})
		}
	}, [alertQueue])

	return null
}

export default ConfirmBox

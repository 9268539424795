import { useEffect } from 'react'
import useLogService from './services/useLogService'

function AppSync() {
	const log = useLogService()

	useEffect(() => {
		window.addEventListener('error', (event) => {
			log({
				APIURL: 'UncaughtError',
				type: 'error',
				level: 'high',
				description: JSON.stringify({
					error: `${event.type}: ${event.message}`,
					debug: `${event.filename} at line ${event.lineno} : ${event.colno}`,
					stack: event.error.stack,
					version: process.env.REACT_APP_VERSION,
				})
			})
		})
	}, [])

	return null
}

export default AppSync

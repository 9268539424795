import { createContext, useReducer } from 'react'
import debounce from 'lodash/debounce'
import { LOCALSTORAGE_KEY, getItem, setItem, removeItem, } from '../services/storageService'
import { generateTxnObj } from '../utills'
import { CURRENT_TXN } from '../constants'

const FuelContext = createContext({})

let initialState = {
	keepAlive: false,
	getItemMrpResp: {},
	currentStep: 'PumpList',
	currentWidget: '',
	vehicleForECOM: '',
	...CURRENT_TXN,
	orderDetails: {},
	orderListByVehicle: [],
	nozzleLst: {},
}

const getInitialState = () => {
	const lastTxn = getItem(LOCALSTORAGE_KEY.txnData, true)
	if (lastTxn) {
		const storageCurrentTxn = JSON.parse(lastTxn)
		return {
			...JSON.parse(JSON.stringify(initialState)),
			...storageCurrentTxn,
		}
	}
	return JSON.parse(JSON.stringify(initialState))
}

const reducers = (prevState, action) => {
	if (process.env.REACT_APP_ENV !== 'production') {
		console.log(action)
	}

	const generateNewState = () => {
		switch (action.type) {
			case 'SET_FUELING_STARTED':
				return {
					...prevState,
					currentTxn: {
						...prevState.currentTxn,
						fuelingStarted: action.data,
					},
				}
			case 'SET_FUEL_INPUT':
				return {
					...prevState,
					currentTxn: {
						...prevState.currentTxn,
						fuelInputes: {
							...prevState.currentTxn.fuelInputes,
							...action.data,
						},
					},
				}
			case 'SET_FUEL_INVOICE_DATA':
				return {
					...prevState,
					currentTxn: {
						...prevState.currentTxn,
						fuelinvoiceData: {
							...prevState.currentTxn.fuelinvoiceData,
							...action.data,
						},
					},
				}
			case 'SET_TEMP_ID':
				return {
					...prevState,
					currentTxn: {
						...prevState.currentTxn,
						TempID: action.data,
					},
				}
			case 'SET_IDENTIFIER':
				return {
					...prevState,
					currentTxn: {
						...prevState.currentTxn,
						Identifier: action.data,
					},
				}
			case 'SET_TCFA_IC':
				return {
					...prevState,
					currentTxn: {
						...prevState.currentTxn,
						tcfaICRes: action.data,
					},
				}
			case 'SET_ORDER_LIST':
				return {
					...prevState,
					orderListByVehicle: action.data,
				}
			case 'SET_ORDER_DETAILS':
				return {
					...prevState,
					orderDetails: { [action.data.CARTID]: action.data },
				}
			case 'SET_NOZZLE_LIST':
				return {
					...prevState,
					nozzleLst: action.data,
				}
			case 'SET_CURRENT_STEP':
				return {
					...prevState,
					currentStep: action.data,
				}
			case 'KEEP_ALIVE':
				return {
					...prevState,
					keepAlive: action.data,
				}
			case 'SET_CURRENT_TXN_PRICE':
				return {
					...prevState,
					currentTxn: {
						...prevState.currentTxn,
						currentTxnPrice: action.data,
					},
				}
			case 'SET_CURRENT_WIDGET':
				return {
					...prevState,
					currentWidget: action.data,
				}
			case 'SET_VEHICLE_ECOM':
				return {
					...prevState,
					vehicleForECOM: action.data,
				}
			case 'CREATE_CURRENT_TXN':
				return { ...prevState, ...action.data, }
			case 'RESET':
				return JSON.parse(JSON.stringify(initialState))
			case 'RESET_CURRENT_TXN':
				return {
					...prevState,
					...JSON.parse(JSON.stringify(CURRENT_TXN)),
					currentStep: 'PumpList',
					keepAlive: false,
				}
			case 'SAFE_RESET':
				if (!prevState.keepAlive) {
					return {
						...prevState,
						...JSON.parse(JSON.stringify(CURRENT_TXN)),
						currentStep: 'PumpList',
						keepAlive: false,
					}
				} else {
					return prevState
				}
			default:
				return { ...prevState, ...action.data, }
		}
	}

	const newState = generateNewState()

	if (action.type === 'RESET_CURRENT_TXN') {
		storageOperation('DELETE')
	} else {
		if (newState.keepAlive) {
			storageOperation('SAVE', {
				currentTxn: newState.currentTxn,
				keepAlive: newState.keepAlive,
				currentStep: newState.currentStep,
			})
		}
	}

	return newState
}

const debounceSetItem = debounce((data) => {
	setItem(LOCALSTORAGE_KEY.txnData, JSON.stringify(data), true)
}, 750)

const storageOperation = (command, data) => {
	if (command === 'SAVE') {
		debounceSetItem(data)
	} else {
		debounceSetItem.cancel()
		removeItem(LOCALSTORAGE_KEY.txnData, true)
	}
}

function FuelProvider({ children }) {
	const [fuelState, fuelDispatch] = useReducer(
		reducers,
		getInitialState()
	)

	if (process.env.REACT_APP_ENV !== 'production') {
		console.log('fuelState', fuelState)
	}

	const setFuelingStarted = (input) => {
		fuelDispatch({ type: 'SET_FUELING_STARTED', data: input })
	}

	const setFuelInput = (input) => {
		fuelDispatch({ type: 'SET_FUEL_INPUT', data: input })
	}

	const setFuelInvoiceData = (input) => {
		fuelDispatch({ type: 'SET_FUEL_INVOICE_DATA', data: input })
	}
	const setTempId = (input) => {
		fuelDispatch({ type: 'SET_TEMP_ID', data: input })
	}
	const setIdentifier = (input) => {
		fuelDispatch({ type: 'SET_IDENTIFIER', data: input })
	}
	const setTcfaIc = (input) => {
		fuelDispatch({ type: 'SET_TCFA_IC', data: input })
	}

	const setOrderList = (input) => {
		fuelDispatch({ type: 'SET_ORDER_LIST', data: input })
	}

	const setOrderDetails = (input) => {
		fuelDispatch({ type: 'SET_ORDER_DETAILS', data: input })
	}
	const setNozzleLst = (input) => {
		fuelDispatch({ type: 'SET_NOZZLE_LIST', data: input })
	}

	const setCurrentStep = (input) => {
		fuelDispatch({ type: 'SET_CURRENT_STEP', data: input })
	}

	const keepTxnAlive = (input) => {
		fuelDispatch({ type: 'KEEP_ALIVE', data: input })
	}

	const setCurrentTxnPrice = (input) => {
		fuelDispatch({ type: 'SET_CURRENT_TXN_PRICE', data: input })
	}

	const setWidget = (input) => {
		fuelDispatch({ type: 'SET_CURRENT_WIDGET', data: input })
	}
	const setVehicleECOM = (input) => {
		fuelDispatch({ type: 'SET_VEHICLE_ECOM', data: input })
	}

	const createCurrentTxn = (fuelData, txnId) => {
		fuelDispatch({
			type: 'CREATE_CURRENT_TXN',
			data: {
				...generateTxnObj(fuelData, txnId),
				keepAlive: true,
				currentStep: 'Fuelling',
			},
		})
	}

	const resetFuelState = () => {
		fuelDispatch({ type: 'RESET' })
	}

	const resetCurrentTxn = () => {
		fuelDispatch({ type: 'RESET_CURRENT_TXN' })
	}

	const safeResetFuelState = () => {
		fuelDispatch({ type: 'SAFE_RESET' })
	}

	return (
		<FuelContext.Provider
			value={{
				fuelState,
				setFuelingStarted,
				setFuelInput,
				setFuelInvoiceData,
				setTempId,
				setIdentifier,
				setTcfaIc,
				setOrderList,
				setOrderDetails,
				setNozzleLst,
				setCurrentTxnPrice,
				setCurrentStep,
				setWidget,
				setVehicleECOM,
				keepTxnAlive,
				createCurrentTxn,
				resetFuelState,
				resetCurrentTxn,
				safeResetFuelState,
			}}
		>
			{children}
		</FuelContext.Provider>
	)
}

export { FuelContext as default, FuelProvider }

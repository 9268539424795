import { useRef } from 'react'

class Observable {
	constructor() {
		this.observer = null
	}

	subscribe(f) {
		this.observer = f
	}

	unSubscribe() {
		this.observer = null
	}

	notify() {
		if (this.observer) {
			this.observer()
		}
	}
}

function useObserver() {
	const observableRef = useRef(new Observable())
	return observableRef.current
}

export default useObserver

/**
 * This is a useful hook to repatedly run any async function or API call in an interval but once finish the previous call
 *
 * ----------------------
 * How to use
 * ----------------------
 * const scheduler = useScheduler();
 *
 * useEffect(() => {
 *    scheduler.subscribe(YOUR_FUNCTION, DURATION, [[FISRT_CALL_PARAMS], [SECOND_TIME_CALL_PARAMS]]);
 *
 *    return () => {
 *        scheduler.unSubscribe();
 *    }
 * });
 */
import { useRef } from 'react'

export function sleep(duration) {
	return new Promise(resolve => {
		setTimeout(() => {
			resolve()
		}, duration)
	})
}

class Observable {
	constructor() {
		this.task = null
		this.duration = 0
		this.params = []
		this.tracking = 0
		this.isPaused = false
	}

	async recursion() {
		if (this.task && !this.isPaused) {
			try {
				const paramLength = this.params.length
				let locParams = []
				if (paramLength > 0) {
					if (this.tracking <= paramLength - 1) {
						locParams = this.params[this.tracking]
					} else {
						locParams = this.params[paramLength - 1]
					}
				}

				this.tracking += 1

				await this.task(...locParams)
			} catch (error) {
				console.error(error)
			} finally {
				await sleep(this.duration)

				this.recursion(this.task, this.duration)
			}
		}
		return
	}

	subscribe(task, duration = 0, params = []) {
		if (typeof task !== 'function') {
			throw new Error('First parameter of subscribe must be a function')
		}
		if (typeof duration !== 'number') {
			throw new Error('Second parameter of subscribe must be a number')
		}

		if (!Array.isArray(params)) {
			throw new Error('Third parameter of subscribe must be an array, 0th for first time task call, 1th for second time and rest. To pass multiple arguments use array of array. ')
		}
		this.task = task
		this.duration = duration
		this.params = params

		this.recursion()
	}

	unSubscribe() {
		this.task = null
		this.duration = 0
		this.params = []
		this.isPaused = false
	}

	pause() {
		this.isPaused = true
	}

	resume() {
		this.isPaused = false
	}
}

function useScheduler() {
	const observableRef = useRef(new Observable())
	return observableRef.current
}

export default useScheduler

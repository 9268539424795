import { useContext } from 'react'
import axios from 'axios'
import find from 'lodash/find'
import { decryptData, Encrypt } from '../Encryption-Decryption'
import { apiTimeOut } from '../constants'
import useLogService from './useLogService'
import AuthContext from '../contexts/authContext'
import useAlertModel from '../contexts/AlertModel'
import { useLogout } from '../hooks'
import { isURL } from '../utills'

function apiLogger(url, req, res) {
	if (process.env.REACT_APP_ENV !== 'production') {
		console.group('%c URL', 'color: #999', url)
		console.log('%c Request ', 'color: #0014ff; font-weight: bold', req)
		console.log('%c Response', 'color: #00b516; font-weight: bold', res)
		console.groupEnd()
	}
}

const useApiCallService = () => {
	const log = useLogService()

	const {
		authState: {
			lstGrpMS, userId, authToken,
			objDeviceSave: { Msg: guid } = {},
			objSupervisorLogin: { Circle_Response: { org: storeId } = {} } = {},
		},
	} = useContext(AuthContext)

	const logoutStateClear = useLogout()

  const doAlert = useAlertModel(s => s.doAlert)

	const createHeaderStoreID = () => {
		const rquestHeader = {
			Accept: 'application/json',
			'Content-Type': 'text/plain',
			Authorization: authToken,
			Guid: guid,
			userid: userId,
			STOREID: storeId,
		}
		return rquestHeader
	}

	const apiCall = async (serviceName, request, options = {}, skipLog = false) => {

		let urlObj = serviceName
		let url = serviceName
		const endpoint = serviceName.split('/').pop()

		if (!isURL(serviceName)) {
			urlObj = find(lstGrpMS, { MICROSERVICENAME: serviceName })
			if (!urlObj) {
				return Promise.reject('Invalid URL')
			}
			url = urlObj.ZONEURL + urlObj.MICROSERVICENAME
		}

		if (url.slice(-5) === '_REST') {
			url = url.substr(0, url.length - 5)
		}

		if (
			process.env.REACT_APP_ENV !== 'production' &&
			process.env.REACT_APP_NODE_INTERCEPTOR_MODE === 'ON'
		) {
			url = `${process.env.REACT_APP_NODE_INTERCEPTOR_URL}/?url=${url}`
		}

		const EncryptedRequest = Encrypt(request)
		try {
			const ajaxOptions = {
				headers: options.headers || createHeaderStoreID(),
			}
			const res = await axios.post(url, EncryptedRequest, ajaxOptions, {
				timeout: apiTimeOut,
			})

			const decryptedResp = decryptData(res.data.replace(/"/g, ''))
			let logDescription = {
				request, response: {
					data: decryptedResp,
					status: res.status,
					statusText: res.statusText,
					headers: res.headers,
				},
			}

			apiLogger(url, request, decryptedResp)

			if (
				decryptedResp.ErrorCode === '0' || decryptedResp.ErrorCode === '00' || decryptedResp.Errorcode === '0' || decryptedResp.Errorcode === '00' || decryptedResp.ErrorCode === '02' || decryptedResp.errorcode === '00' || decryptedResp.strResponse === "" || decryptedResp.errorCode === '00'
			) {
				if (!skipLog) {
					log({ APIURL: urlObj, description: JSON.stringify(logDescription), })
				}

				return decryptedResp
			}

			if (decryptedResp?.ErrorMsg?.toLowerCase().includes('no data') ||
				decryptedResp?.errorMsg?.toLowerCase().includes('no data')
			) {
				return decryptedResp
			}

			if (!skipLog) {
				log({
					APIURL: urlObj, description: JSON.stringify(logDescription),
					type: 'debug', level: 'high',
				})
			}

			if (
				decryptedResp.ErrorCode === '03' || decryptedResp.ErrorCode === '3' || decryptedResp.Errorcode === '03' || decryptedResp.Errorcode === '3'
			) {
				logoutStateClear()
				doAlert({
					title: `API: ${endpoint}`,
					message: decryptedResp.ErrorMsg || decryptedResp.Errormsg || decryptedResp.errormsg || decryptedResp.errorMsg || '',
					buttons: [{ label: 'Close' }],
				})
			} else {
				if (!options.hideError) {
					doAlert({
						title: `API: ${endpoint}`,
						message: decryptedResp.ErrorMsg || decryptedResp.Errormsg || decryptedResp.errormsg || decryptedResp.Statusmsg || decryptedResp.errorMsg || '',
						buttons: [{ label: 'Close' }],
					})
				}
			}

			return Promise.reject(decryptedResp)
		} catch (err) {
			apiLogger(url, request, err)
			doAlert({
				title: `API: ${endpoint}`,
				message: 'Something went wrong',
				buttons: [{ label: 'Close' }],
			})

			log({
				APIURL: urlObj,
				type: 'debug',
				level: 'high',
				description: JSON.stringify({
					request,
					response: { error: err.message, stack: err.stack },
				}),
			})
			return Promise.reject(err)
		}
	}

	return apiCall
}

export default useApiCallService

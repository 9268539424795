import { useCallback, useEffect, useRef, useState } from 'react'

const Calculator = () => {
	const [input, setInput] = useState([])
	const previousResult = useRef('')
	const shouldICalcTotal = useRef(false)

	useEffect(() => {
		if (shouldICalcTotal.current) {
			try {
				const totalString = eval(input.join(''))
				if (isNaN(totalString) || totalString === Infinity) {
					throw new Error('Divide by 0')
				}

				previousResult.current = totalString
				setInput(() => [totalString])
			} catch (error) {
				setInput(() => [])
				alert('Unexpected input in eval: ' + error)
			}
			shouldICalcTotal.current = false
		}
	}, [shouldICalcTotal, input])

	const handleInput = useCallback(e => {
		const value = e.target.value
		const operatorsBasic = ["+", "-", "/", "*"]
		const operatorsDot = ["."]

		if (value === "ac") {
			previousResult.current = ''
			setInput(() => [])
			return
		}

		if (value === "ce") {
			previousResult.current = ''
			setInput(prev => prev.slice(0, prev.length - 1))
			return
		}

		if (value === "=") {
			setInput(prev => prev.concat(''))
			shouldICalcTotal.current = true
			return
		}

		if (value === "." && operatorsDot.includes(input[input.length - 1])) {
			return
		}

		setInput(prev => (!prev.length && operatorsBasic.includes(value) && previousResult.current)
			? prev.concat(previousResult.current).concat(value)
			: operatorsBasic.includes(prev[prev.length - 1]) && operatorsBasic.includes(value)
				? prev
				: prev.concat(value)
		)
		previousResult.current = ''
	}, [])

	return (
		<div className="calculator card mb-4">
			<span className="calculator-screen z-depth-1">{input.join('') || '0'}</span>
			<div className="calculator-keys">
				<button type="button" onClick={handleInput} className="btn" value="ac">AC</button>
				<button type="button" onClick={handleInput} className="btn" value="ce">CE</button>
				<button type="button" onClick={handleInput} className="btn" value="%">%</button>
				<button type="button" onClick={handleInput} className="operator btn" value="/">&divide;</button>
				<button type="button" onClick={handleInput} className="btn" value="7">7</button>
				<button type="button" onClick={handleInput} className="btn" value="8">8</button>
				<button type="button" onClick={handleInput} className="btn" value="9">9</button>
				<button type="button" onClick={handleInput} className="operator btn" value="*">&times;</button>
				<button type="button" onClick={handleInput} className="btn" value="4">4</button>
				<button type="button" onClick={handleInput} className="btn" value="5">5</button>
				<button type="button" onClick={handleInput} className="btn" value="6">6</button>
				<button type="button" onClick={handleInput} className="operator btn" value="-">-</button>
				<button type="button" onClick={handleInput} className="btn" value="1">1</button>
				<button type="button" onClick={handleInput} className="btn" value="2">2</button>
				<button type="button" onClick={handleInput} className="btn" value="3">3</button>
				<button type="button" onClick={handleInput} className="operator btn" value="+">+</button>
				<button type="button" onClick={handleInput} className="btn" value="0">0</button>
				<button type="button" onClick={handleInput} className="btn" value=".">.</button>
				<button type="button" onClick={handleInput} className="btn" value=",">,</button>
				<button type="button" onClick={handleInput} className="operator btn" value="=">=</button>
			</div>
		</div>
	)
}

export default Calculator

export const agentAuthFilter = res => ({
	objSupervisorLogin: {
		lstAuth_Config: res?.objSupervisorLogin?.lstAuth_Config,
		Circle_Response: {
			org: res?.objSupervisorLogin?.Circle_Response?.org,
		},
		Store_State: res?.objSupervisorLogin?.Store_State,
		Store_Name: res?.objSupervisorLogin?.Store_Name,
	},
	objDeviceSave: {
		Msg: res?.objDeviceSave?.Msg,
	},
	lstGrpMS: res?.lstGrpMS,
})

export const deviceFilter = res => ({
	objGetStore: {
		POSID: res?.objGetStore?.POSID,
		Headerlines: res?.objGetStore?.Headerlines,
		Footerlines: res?.objGetStore?.Footerlines,
	},
	objGetTenderListResp: {
		TenderListAndroid: res?.objGetTenderListResp?.TenderListAndroid.map(item => ({
			Tender_ID: item.Tender_ID,
			Tender_Name: item.Tender_Name,
			Tender_Mode: item.Tender_Mode,
			TransactionType: item.TransactionType,
		})),
	},
	DOMIP: res?.DOMSIP || '',
	lstFuelProducts: res?.lstFuelProducts || [],
})

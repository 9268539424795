import Modal from './Modal'

function StyledModal({ open, onClose, title, children }) {
	return (
		<Modal open={open} onClose={onClose}>
		<div className="modal-box">
			<div className="modal-header">
				<h3 className="font-2 m-0">{title}</h3>
				<i className="fa fa-2x fa-times" onClick={onClose} />
			</div>
			<div className="modal-body">{children}</div>
		</div>
		</Modal>
	)
}
export default StyledModal

import { create } from 'zustand'

const useAlertModel = create(set => ({
	alertQueue: [],

	doAlert: item => set(prev => ({ alertQueue: prev.alertQueue.concat(item) })),
	popAlert: () => set(prev => ({ alertQueue: prev.alertQueue.slice(1) })),
}))

export default useAlertModel

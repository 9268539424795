import { memo, useState } from 'react'
import pestriesImg from '../img/payment/pestries.jpg'

function PaymentDetails() {
	const [state, _setState] = useState({
		cartItems: [],
		cartInputs: {
			taxableAmt: 0,
			totalCGST: 0,
			totalSGST: 0,
			totalUGST: 0,
			totalCartAmt: 0
		}
	})

	const updateCart = (_item, _itemQty) => {
		// do something
	}

	return (
		<div className="card payment">
			<div className="card-header">
				<div className="row mt-2">
					<div className="col-md-12 mt-2">
						<h4>Payment Details</h4>
					</div>
				</div>
			</div>
			<div className="card-body">
				<div className="payment-details scroll">
					<ul>
					{state.cartItems.map((item, i) => (
						<li key={i}>
							<div className="pro-details">
								<img alt={item.itmDesc} src={pestriesImg} />
								<h4>{item.itmDesc}<span><i className="fas fa-rupee-sign"></i>{item.itmPrc}</span></h4>
								<div className="input-group" style={{ "align-items": "flex-end" }}>
									<div className="input-group-prepend">
										<button className="btn left" type="button" style={{ "z-index": "auto" }} onClick={() => updateCart(item, item.itmQty - 1)}><i className="fas fa-minus"></i></button>
									</div>
									<input type="text" className="form-control" value={item.itmQty} />
									<div className="input-group-prepend" onClick={() => updateCart(item, item.itmQty + 1)}>
										<button className="btn right" type="button" style={{ "z-index": "auto" }} ><i className="fas fa-plus"></i></button>
									</div>
								</div>
							</div>
						</li>
					))}
					</ul>
				</div>
			</div>
			<div className="sub-footer">
				<p>Gross Total<span><i className="fas fa-rupee-sign"></i>{state.taxableAmt}</span></p>
				<p>CGST<span><i className="fas fa-rupee-sign"></i>{state.totalCGST}</span></p>
				<p>SGST<span><i className="fas fa-rupee-sign"></i>{state.totalSGST}</span></p>
				<p>UGST<span><i className="fas fa-rupee-sign"></i>{state.totalUGST}</span></p>
			</div>
			<div className="card-footer">
				<p>Total Amount <span><i className="fas fa-rupee-sign"></i>{state.totalCartAmt ? Math.round((state.totalCartAmt + Number.EPSILON) * 100) / 100 : 0}</span></p>
			</div>
		</div>
	)
}

export default memo(PaymentDetails)

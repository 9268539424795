import { createContext, useReducer } from 'react'
import { LOCALSTORAGE_KEY, getItem } from '../services/storageService'

const keepLogin = true

const AuthContext = createContext({})

let defaultState = {
	objSupervisorLogin: {}, objDeviceSave: {}, lstGrpMS: [],

	objGetStore: {}, objGetTenderListResp: {}, DOMIP: '', lstFuelProducts: [],

	userId: "", password: "", LogInTime: "", authToken: "",
}

let initialState = { ...defaultState }

if (keepLogin) {
	const appconfig = getItem(LOCALSTORAGE_KEY.appConfig)
	if (appconfig) {
		initialState = { ...initialState, ...JSON.parse(appconfig) }
	}

	const userId = getItem(LOCALSTORAGE_KEY.userId) || ''
	const password = getItem(LOCALSTORAGE_KEY.password) || ''
	const LogInTime = getItem(LOCALSTORAGE_KEY.loginTime) || ''
	const authToken = getItem(LOCALSTORAGE_KEY.authToken) || ''

	initialState = { ...initialState, userId, password, LogInTime, authToken }
}

const reducers = (prevState, action) => {
	if (process.env.REACT_APP_ENV !== 'production') {
		console.log(action)
	}
	switch (action.type) {
		case 'RESET':
			return defaultState
		default:
			return { ...prevState, ...action.data }
	}
}

const AuthProvider = ({ children }) => {
	const [authState, authDispatch] = useReducer(reducers, initialState)
	if (process.env.REACT_APP_ENV !== 'production') {
		console.log('authState', authState)
	}
	const resetAuthState = () => {
		authDispatch({ type: 'RESET' })
	}
	return (
		<AuthContext.Provider value={{ authState, authDispatch, resetAuthState }}>
			{children}
		</AuthContext.Provider>
	)
}

export { AuthContext as default, AuthProvider }

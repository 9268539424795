import { useContext } from "react"
import { useHistory } from 'react-router-dom'
import AuthContext from '../contexts/authContext'
import FuelContext from '../contexts/fuelContext'
import { storageClear } from '../services/storageService'
import route from '../route'

function useLogout() {
	const { resetAuthState } = useContext(AuthContext)
	const { resetFuelState } = useContext(FuelContext)
	const history = useHistory()

	const logoutStateClear = () => {
		storageClear()
		resetFuelState()
		resetAuthState()
		history.push(route.login.path)
	}

	return logoutStateClear
}

export default useLogout

import { useContext } from 'react'
import useApiCallService from '../useApiCallService'
import AuthContext from '../../contexts/authContext'

function useReportService() {
	const {
		authState: {
			objDeviceSave: { Msg: guid } = {},
			objSupervisorLogin: { Circle_Response: { org: storeId } = {} } = {},
			objGetStore: { POSID } = {},
		}
	} = useContext(AuthContext)
	const apiCall = useApiCallService()

	const fetchTids = async ({ FpID }) => {
		const Request = {
			"StoreId": storeId,
			"Guid": guid,
			"DeviceId": `${storeId}${POSID}`,
			"FpID": FpID,
			"Type": ""
		}
		return await apiCall('FuelReprintTxnDetails', Request)
	}

	const fetchTrxn = async ({ TxnId, FpID, TxnStartTime, DeviceID }) => {
		const Request = {
			"FpID": FpID,
			"TxnId": TxnId,
			"GUID": guid,
			"StoreID": storeId,
			"DeviceId": DeviceID,
			"TxnStartDateTime": TxnStartTime
		}
		return await apiCall(`${process.env.REACT_APP_API_HOST}/DOMSOtherService/api/v1.0/FpQuickTxnPrint`, Request, { hideError: true })
	}

	const fpQuickPrint = async ({ FpID }) => {
		const Request = {
			"FpID": FpID,
			"StoreID": storeId,
			"GUID": guid
		}

		return await apiCall('FpQuickPrint', Request, { hideError: true })
	}

	return {
		fetchTids,
		fetchTrxn,
		fpQuickPrint
	}
}
export default useReportService

import { useState, useContext } from "react"
import { useHistory } from 'react-router-dom'
import dayjs from 'dayjs'
import { EncryptNew } from '../Encryption-Decryption'
import useAuthService from '../services/apiServices/useAuthService'
import { basicAuth } from "../BasicAuth"
import AuthContext from '../contexts/authContext'
import useAppModel from '../contexts/AppModel'
import FuelContext from '../contexts/fuelContext'

import { LOCALSTORAGE_KEY, setItem, getItem } from '../services/storageService'
import route from '../route'
import useLogout from './useLogout'
import { agentAuthFilter } from '../utills/login'

function useLogin() {
	const [userId, setUserId] = useState('')
	const [password, setPassword] = useState('')
	const { authDispatch } = useContext(AuthContext)
	const showLoader = useAppModel(s => s.showLoader)
	const hideLoader = useAppModel(s => s.hideLoader)
	const history = useHistory()
	const logoutStateClear = useLogout()
	const { loginAPI, logoutAPI } = useAuthService()

	const { setWidget } = useContext(FuelContext)

	const onLogin = async e => {
		e.preventDefault()
		try {
			showLoader('Logging in...')

			const authRes = await loginAPI({ userId, password })
			const filterRes = agentAuthFilter(authRes)
			const authToken = EncryptNew('Basic ' + basicAuth(userId, password))

			const loginTime = dayjs().format('DD/MM/YYYY HH:mm:ss')
			authDispatch({ data: { ...filterRes, userId, password, authToken, LogInTime: loginTime } })

			setItem(LOCALSTORAGE_KEY.appConfig, JSON.stringify(filterRes))
			setItem(LOCALSTORAGE_KEY.userId, userId)
			setItem(LOCALSTORAGE_KEY.password, password)
			setItem(LOCALSTORAGE_KEY.storeId, filterRes.objSupervisorLogin.Circle_Response.org)
			setItem(LOCALSTORAGE_KEY.guid, filterRes.objDeviceSave.Msg)
			setItem(LOCALSTORAGE_KEY.authToken, authToken)
			setItem(LOCALSTORAGE_KEY.loginTime, loginTime)

			const pCode = getItem(LOCALSTORAGE_KEY.pCode, true)
			if (pCode) {
				setWidget(pCode)
			}
			history.push(route.dashboard.path)
		} catch (error) {
			console.error(error)
		} finally {
			hideLoader()
		}
	}

	const onLogout = async () => {
		try {
			showLoader('Logging out...')
			await logoutAPI()
			logoutStateClear()
		} catch (error) {
			console.error(error)
		} finally {
			hideLoader()
		}
	}

	return {
		userId, setUserId, password, setPassword,
		onLogin, onLogout,
	}
}

export default useLogin

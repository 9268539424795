import { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthContext from './contexts/authContext'
import routePath from './route'

const PrivateRoute = ({ path, component: Component, ...rest }) => {
	const { authState } = useContext(AuthContext)
	const renderRoute = routeProps => {
		if (authState?.objDeviceSave?.Msg && path === routePath.login.path) {
			return <Redirect to={routePath.dashboard.path} />
		}
		if (!authState?.objDeviceSave?.Msg && path !== routePath.login.path) {
			return <Redirect to={routePath.login.path} />
		}

		return <Component {...routeProps} />
	}

	return <Route {...rest} path={path} render={renderRoute} />
}

export default PrivateRoute

import { useContext } from 'react'
import axios from 'axios'
import find from 'lodash/find'
import AuthContext from '../contexts/authContext'
import FuelContext from '../contexts/fuelContext'
import { Encrypt } from '../Encryption-Decryption'
import { apiTimeOut } from '../constants'
import { isURL } from '../utills'

const useLogService = () => {
	const {
		authState: {
			lstGrpMS, userId, authToken,
			objDeviceSave: { Msg: guid } = {},
			objSupervisorLogin: { Circle_Response: { org: storeId } = {} } = {},
			objGetStore: { POSID } = {}
		}
	} = useContext(AuthContext)

	const { fuelState: {
		currentTxn: {
			Identifier,
			fuelInputes: { MDUSelectedOrder } = {},
			fuelinvoiceData: { TaxInvoice } = {},
		} = {}
	} = {} } = useContext(FuelContext)

	const getURL = (serviceName) => {
		const APIURL = find(lstGrpMS, { MICROSERVICENAME: serviceName })
		if (APIURL) {
			return APIURL.ZONEURL + APIURL.MICROSERVICENAME
		}

		return null
	}

	const log = async (request = {}) => {
		if (process.env.REACT_APP_ENV === 'development') {
			console.log('API LOG: ', request)
			return
		}

		try {
			const logUrl = getURL('saveLog')

			if (logUrl && request.APIURL) {
				let methodName = ''
				if (isURL(request.APIURL)) {
					const trimServiceName = request.APIURL.split('/')
					methodName = trimServiceName[trimServiceName.length - 1]
				}
				let url = request.APIURL
				if (typeof request.APIURL === 'object') {
					methodName = request.APIURL.MICROSERVICENAME
					url = request.APIURL.ZONEURL + request.APIURL.MICROSERVICENAME
				}

				if (url.slice(-5) === '_REST') {
					url = url.substring(0, url.length - 5)
					methodName = methodName.substring(0, methodName.length - 5)
				}

				if (request.type === 'error') {
					methodName = url
				}

				const payload = {
					"APIURL": url,
					"methodName": methodName,
					"description": request.description.replaceAll('|', '/'),

					"infoType": request.type || "info",
					"severity": request.level || "low",
					"field1": MDUSelectedOrder || Identifier || "",
					"field2": TaxInvoice || "",
					"exceptionName": '', //

					"agentID": POSID, "StoreID": storeId, "GUID": guid, "sessionID": '',

					"applicationName": '',
					"applicationType": '',
					"clientIP": '',
					"dateTime": '',
					"infoNature": '',
					"originAppServer": '',
					"serviceName": '',
					"storeName": '',
					"userAgent": '',
				}

				const headers = {
					Accept: 'application/json',
					'Content-Type': 'text/plain',
					Authorization: authToken,
					Guid: guid,
					userid: userId,
					STOREID: storeId
				}

				const EncryptedRequest = Encrypt(payload)

				await axios.post(logUrl, EncryptedRequest, { headers }, { timeout: apiTimeOut })
			}
		} catch (error) {
			console.error('Error in log service', error)
		}
	}

	return log
}

export default useLogService

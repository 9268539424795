import { useEffect } from 'react'
import useAppModel from '../contexts/AppModel'
import { useScheduler } from '../hooks'

function OfflineChecker() {
	const setOfflineMode = useAppModel(s => s.setOfflineMode)
	const scheduler = useScheduler()

	useEffect(() => {
		const internetCheck = async () => {
			if (process.env.REACT_APP_ENV !== 'development') {
				try {
					await fetch(process.env.REACT_APP_PING_URL, { cache: 'no-cache' })
					setOfflineMode(false)
				} catch (error) {
					setOfflineMode(true)
				}
			}
		}
		scheduler.subscribe(internetCheck, 60000)

		return () => {
			scheduler.unSubscribe()
		}
	}, [])

	return null
}

export default OfflineChecker

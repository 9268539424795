import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import useAppModel from '../contexts/AppModel'
import FuelContext from '../contexts/fuelContext'
import { PUMP_TYPE, FUEL_CODE } from '../constants'
import routePath from '../route'
import petrolImg from '../img/fuel/petrol.png'
import dieselImg from '../img/fuel/diesel-icon.png'
import alpglImg from '../img/fuel/gas-cylinder.png'
import { LOCALSTORAGE_KEY, setItem } from '../services/storageService'
import Space from '../common/Space'

const productImg = { MS: petrolImg, HSD: dieselImg, ALPG: alpglImg, ECOM: dieselImg }

function FuelWidgetItem({ productItem, priceFetch: callFetchPrice }) {
	const {
		fuelState: {
			currentTxn: { fuelInputes: { selectedType } = {} } = {},
			keepAlive
		} = {},
		setFuelInput, setCurrentTxnPrice, setWidget
	} = useContext(FuelContext)
	const prices = useAppModel(s => s.prices)
	const history = useHistory()

	const pCode = FUEL_CODE[productItem.ProductID]

	const handleLink = async () => {
		if (! keepAlive) {
			await callFetchPrice(false)
			setFuelInput({ fuelType: PUMP_TYPE[pCode], selectedType: pCode })
			setCurrentTxnPrice(prices[pCode])
			setWidget(pCode)
			setItem(LOCALSTORAGE_KEY.pCode, pCode, true)
			history.push(routePath.fuelSale.path)
			return true
		}

		if (selectedType === pCode) {
			history.push(routePath.fuelSale.path)
		}
		return true
	}

	const currentWid = keepAlive && (selectedType === pCode || (pCode === 'ECOM' && ['ECOM', 'MDU', 'PFC', 'PFCJIO'].includes(selectedType)))
	return (
		<div onClick={handleLink} className={!keepAlive || currentWid ? 'category' : 'category disabled'} role="presentation">
			<div style={{ padding: "20px", gap: '10px', minWidth: '220px' }} className="row">
				<div>
					<div className="icon d-flex justify-content-center align-items-center flex-column">
						<img src={productImg[pCode]} alt="" />
					</div>
				</div>
				<div className="d-flex justify-content-center align-items-center flex-column">
					<h4>{productItem.ProductName}</h4>
					<h3>{prices[pCode] ? `Rs. ${prices[pCode]}` : <Space />}</h3>
				</div>
			</div>
		</div>
	)
}

export default FuelWidgetItem

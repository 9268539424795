import { useContext } from 'react'
import Calculator from './Calculator'
import PaymentDetails from './PaymentDetails'
import HomeFuelingContext from './context/homeFuelingContext'

function HomeRightCol() {
	const { contextDisplayFueling } = useContext(HomeFuelingContext)

	return (
		<div className={`row home-row animate__animated ${contextDisplayFueling ? " animate__slideOutRight" : " animate__slideInRight"}`}>
			<div className="col-lg-7 pb-3">
				<Calculator />
			</div>
			<div className="col-lg-5">
				<PaymentDetails />
			</div>
		</div>
	)
}

export default HomeRightCol

import { Suspense, lazy, useContext, useEffect, useState } from 'react'
import AuthContext from './contexts/authContext'
import FuelContext, { FuelProvider } from './contexts/fuelContext'
import useAuthService from './services/apiServices/useAuthService'
import useFuelService from './services/apiServices/useFuelService'
import { Switch } from 'react-router-dom'
import PrivateRoute from './PrivateRoute'
import routePath from './route'
import OfflineChecker from './common/OfflineChecker'
import { FUEL_CODE } from './constants'
import Spinner from './common/Spinner'
import HomeNew from './Home/HomeNew'
import { deviceFilter } from './utills/login'
import logo from './img/login-logo.png'

const FuelSaleNew = lazy(() => import('./FuelSale/FuelSaleNew'))
const Transactions = lazy(() => import('./Report/Transactions'))
const ComingSoon = lazy(() => import('./common/ComingSoon'))

function MainContentChild() {
	const [inProgress, setInProgress] = useState(true)
	const { authState, authDispatch } = useContext(AuthContext)
	const { setNozzleLst } = useContext(FuelContext)
	const { launchDeviceSellAPI } = useAuthService()
	const { fetchProductWiseFpList } = useFuelService()

	useEffect(() => {
		const callDeviceSell = async () => {
			try {
				const deviceSellRes = await launchDeviceSellAPI()
				const filteredRes = deviceFilter(deviceSellRes)

				authDispatch({ data: { ...filteredRes, }, })
				const prdctCode = []
				const nzLst = {}
				const apiStack = filteredRes.lstFuelProducts.map((productItem) => {
					const pCode = FUEL_CODE[productItem.ProductID]
					prdctCode.push(pCode)
					return fetchProductWiseFpList({ fuelType: pCode, hideError: true })
				})
				const response = await Promise.allSettled(apiStack)
				response.forEach((itemRes, i) => {
					if (itemRes.status === 'fulfilled' && itemRes?.value?.lstFpIds) {
						nzLst[prdctCode[i]] = itemRes.value.lstFpIds
					}
				})
				setNozzleLst(nzLst)
				setInProgress(false)
			} catch (error) {
				console.error(error)
			}
		}
		if (authState?.objDeviceSave?.Msg) {
			callDeviceSell()
		}
	}, [authState?.objDeviceSave?.Msg])

	if (inProgress) {
		return (
			<div className="app-init-loader">
				<img alt="Jio BP" src={logo} />
				<p>Loading...</p>
			</div>
		)
	}

	return (
		<Suspense fallback={<Spinner />}>
			<Switch>
				<PrivateRoute exact path={routePath.dashboard.path} component={HomeNew} />
				<PrivateRoute exact path={routePath.fuelSale.path} component={FuelSaleNew} />
				<PrivateRoute exact path={routePath.report.path} component={Transactions} />
				<PrivateRoute exact path={routePath.comingSoon.path} component={ComingSoon} />
			</Switch>
			<OfflineChecker />
		</Suspense>
	)
}

const MainContent = () => (
	<FuelProvider>
		<MainContentChild />
	</FuelProvider>
)

export default MainContent

import { INVOICE_FLEET_FIELD, MOP_ID } from '../constants'

const STR_LENGTH = 40
const rAlign = (left, right = '') => left + `${right}`.padStart(STR_LENGTH - left.length, ' ')
const cAlign = text => `${text}`.padStart(STR_LENGTH - Math.ceil((STR_LENGTH - text.length) / 2), ' ')

const headerFooter = (lineList = [], lineObjList = []) => {
	if (lineObjList.length !== 0) {
		return lineObjList.map(element => (
			element?.IS_CENTER === '1' ? cAlign(element.LINE_TEXT) : element.LINE_TEXT
		)).join("\n")
	}

	return lineList.join("\n")
}

export default function receiptPrint({
	storeCode = '',
	isECOM = false, orderId = '', time = '',
	FPID = '', FpGradeOptionNo = '', TaxInvoice = '', TxnStartTime = '', productCode = '', fuelType = '',
	totalVol = '', fuelPrice = '', totalAmt = '', cardNo = '', authCode = '', mopId = '', mopValue = '', fleetCard = '', fleetMode = '',
	ContactNumber = '', vehicleNo = '', dsmId = '', refId = '',
	rewardMeter = { points: null, mobile: '' },
 }, Headerlines, Footerlines, HeaderlinesArray = [], FooterlinesArray = []) {

	const totalAmtStr = parseFloat(totalAmt).toFixed(2)
	const totalVolStr = parseFloat(totalVol).toFixed(2)
	const fuelPriceStr = parseFloat(fuelPrice).toFixed(2)

	let rewardMeterPointsStr = ''
	if (rewardMeter.points !== null) {
		rewardMeterPointsStr = `[L]Loyalty Card / Mob: ${rewardMeter.mobile}
[L]REWARDMETER Loyalty Point(s): ${rewardMeter.points}`
	}

	const metaString = []
	if (mopId === '27') {
		metaString.push(`[L]${INVOICE_FLEET_FIELD[fleetMode]} ${fleetCard}`, `[L]Fleet invoice: ${refId}`, `[L]Auth Code: ${authCode}`)
	} else if (mopId === '2') {
		metaString.push(`[L]Card No: ${cardNo}`, `[L]Auth Code: ${authCode}`)
	} else if (mopId === `${MOP_ID['paytm']}`) {
		metaString.push(`[L]Card No: ${cardNo}`, `[L]RRN No: ${refId}`)
	}
	if ([MOP_ID['clmVoucher'], MOP_ID['voucherRedemption']].includes(parseInt(mopId, 10))) {
		metaString.push(`[L]Voucher No: ${fleetCard}`)
	}
	if (orderId !== '' && orderId[0] !== 'F') {
		metaString.push(`[L]Order no: ${orderId}`)
	}

	if (![MOP_ID['pumpTest'], MOP_ID['ownuse']].includes(parseInt(mopId, 10))) {
		if (ContactNumber && !(mopId === '27' && ['M', 'I'].includes(fleetMode))) {
			metaString.push(`[L]Mobile No: ${ContactNumber}`)
		}
		if (vehicleNo) {
			metaString.push(`[L]Vehicle No: ${vehicleNo}`)
		}
	}

	const headerDetails = metaString.join("\n")
	let itemDetails = ''
	if (isECOM) {
		itemDetails = `${fuelType}
${rAlign(`${productCode}  ${totalVolStr.padStart(9, ' ')}  Ltr${fuelPriceStr.padStart(8, ' ')}`, totalAmtStr)}`
	} else {
		itemDetails = `${rAlign(`${fuelType}  ${totalVolStr.padStart(5, ' ')}  Ltr${fuelPriceStr.padStart(8, ' ')}`, totalAmtStr)}`
	}

	const str = `${headerFooter(Headerlines, HeaderlinesArray)}\n
${cAlign(`RO ID: ${storeCode}`)}
${cAlign('Customer Copy')}
[L]Inv: ${TaxInvoice}
[L]Date: ${TxnStartTime}
${headerDetails}
----------------------------------------
${rAlign('Item      Qty  UoM Rate(Rs)', 'Amt(Rs)')}
${itemDetails}
----------------------------------------
${rAlign('Total Invoice Amount:', `Rs. ${totalAmtStr}`)}
${rAlign('Method of Payment:', mopValue)}
${rAlign('Amount Tendered:', `Rs. ${totalAmtStr}`)}
${rewardMeterPointsStr}
----------------------------------------
${headerFooter(Footerlines, FooterlinesArray)}\n
[L]FP-${FPID}-${FpGradeOptionNo}/DSM-${dsmId} ${time}`

	return str
}

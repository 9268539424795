import { useState } from 'react'

function QuickPrintModal({ closeModal, onSubmit, btnLabel = 'Continue' }) {
	const [fpNo, setFpNo] = useState('')
	const handlePrint = () => {
		if (fpNo) {
			onSubmit(fpNo)
		}
	}

	return (<>
		<input autoFocus type="text" inputMode="decimal" className="form-control form-control-lg" placeholder="FP No." value={fpNo} onChange={e => setFpNo(e.target.value)} />
		<button type="button" className="btn btn-lg btn-outline-success mt-4" onClick={handlePrint}>{btnLabel}</button>
	</>)
}

export default QuickPrintModal

import { useState } from 'react'
import isEmpty from 'lodash/isEmpty'
import StyledModal from '../common/StyledModal'
import QuickPrintModal from './QuickPrintModal'
import PrintReceipt from '../PrintReceipt'

function QuickPrintButton() {
	const [printData, setPrintData] = useState({})
	const [isOpen, setIsOpen] = useState(false)

	const handlePrint = async FpID => {
		setPrintData({ FpID })
		setIsOpen(false)
	}
	const closeModal = () => {
		setIsOpen(false)
		setPrintData({})
	}
	const openFpModal = () => {
		setIsOpen(true)
	}

	return (
		<>
			<button style={{ "background": "center" }} className="side-menu-item" type="button" onClick={openFpModal}>
				<h3 className="icon-menu-print" />
				<span>Print</span>
			</button>

			<StyledModal title="Pump No" open={isOpen} onClose={closeModal}>
				<QuickPrintModal closeModal={closeModal} onSubmit={handlePrint} btnLabel="Print" />
			</StyledModal>

			<PrintReceipt
				allowInputs={true}
				saveInvoice={true}
				isOpen={!isEmpty(printData)}
				onRequestClose={closeModal}
				FpID={printData.FpID}
				TxnId={printData.TxnId}
			/>
		</>
	)
}

export default QuickPrintButton

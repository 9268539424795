import { useContext } from 'react'
import dayjs from 'dayjs'
import { EncryptNew } from '../../Encryption-Decryption'
import AuthContext from '../../contexts/authContext'
import { basicAuth } from "../../BasicAuth"
import useApiCallService from '../useApiCallService'
import { getItem, setItem, LOCALSTORAGE_KEY } from '../storageService'
import { getMacId } from '../macIdAndroidService'

let commonMacId = '02:E7:0B:8C:B2:89'
const imei = '867981022425287';

(async function () {
	try {
		console.log('window.Android', window.Android)
		if (window.Android) {
			commonMacId = await getMacId()
			if (commonMacId) {
				setItem(LOCALSTORAGE_KEY.macId, commonMacId)
			}
		}
	} catch (error) { console.error(error) }
})()

const tmpMacId = getItem(LOCALSTORAGE_KEY.macId)
if (!tmpMacId) {
	setItem(LOCALSTORAGE_KEY.macId, commonMacId)
}

function useAuthService() {
	const {
		authState: {
			userId, password, DOMIP,
			objDeviceSave: { Msg: guid } = {},
			objSupervisorLogin: { Circle_Response: { org: storeId } = {} } = {},
			objGetStore: { POSID } = {},
		},
	} = useContext(AuthContext)

	const apiCall = useApiCallService()

	const loginAPI = async ({ userId, password }) => {
		const macId = getItem(LOCALSTORAGE_KEY.macId) || commonMacId
		const date = new Date().getDate()
		const month = new Date().getMonth() + 1
		const year = new Date().getFullYear()
		const hours = new Date().getHours()
		const min = new Date().getMinutes()
		const sec = new Date().getSeconds()
		const Finaldate = (date + "-" + month + "-" + year + " " + hours + ":" + min + ":" + sec)
		const Request = {
			"MacID": macId,
			"UserID": userId,
			"Password": password,
			"DeviceDate": Finaldate,
			"VersionNo": "7.0.2.3",
			"GuId": "",
			"Node": null,
			"OTPValidated": null,
			"IMEI": imei
		}

		const responseJson = await apiCall(
			`${process.env.REACT_APP_API_HOST}/Micro_Authentication/api/v1.0/AgentAuthentication`,
			Request,
			{
				headers: {
					Accept: 'application/json',
					'Content-Type': 'text/plain',
					Authorization: EncryptNew('Basic ' + basicAuth(userId, password)),
					userid: EncryptNew(userId),
					STOREID: ""
				}
			}
		)

		return responseJson
	}

	const logoutAPI = async () => {
		var Finaldate = dayjs().format('HHmmss')
		var Request = {
			"StoreNo": storeId,
			"RegisterNo": POSID,
			"EmpID": userId,
			"LogOffTime": Finaldate,
			"guId": guid
		}
		return await apiCall('DoLogOff', Request)
	}

	const launchDeviceSellAPI = async () => {
		const macId = getItem(LOCALSTORAGE_KEY.macId) || commonMacId
		const date = new Date().getDate()
		const month = new Date().getMonth() + 1
		const year = new Date().getFullYear()
		const hours = new Date().getHours() //Current Hours
		const min = new Date().getMinutes() //Current Minutes
		const sec = new Date().getSeconds() //Current Seconds
		const Finaldate = (date + "-" + month + "-" + year + " " + hours + ":" + min + ":" + sec)

		const Request2 = {
			"MacId": macId,
			"EmpID": userId,
			"PassWord": password,
			"FetchDeviceDetails": true,
			"StoreID": storeId,
			"POSID": "",
			"DeviceDate": Finaldate,
			"buttonVersion": "",
			"tenderVersion": "",
			"guId": guid
		}

		const responseJson = await apiCall(
			`${process.env.REACT_APP_API_HOST}/Micro_Authentication/api/v1.0/LaunchDeviceSell`,
			Request2,
		)
		return responseJson
	}

	const fetchPrices = async (hideError = false) => {
		const Request = {
			"Host": DOMIP,
			"PosId": POSID,
			StoreID: storeId,
			GUID: guid
		}

		if (process.env.REACT_APP_ENV === 'development') {
			return { "ErrorCode": "00", "ErrorMsg": "SUCCESS", "FcPrices": { "UserId": "WEB", "FcPriceSetId": "1", "FcPriceGroupId": ["1"], "FcGradeId": ["20", "10", "30"], "FcGradeName": ["HSD", "MS", "ALPG"], "FcPriceGroups": [["40.00", "99.00", "55.00"]], "FcPriceSetDateAndTime": "20230310175822", "PriceSetActivationDateAndTime": "00000000000000" } }
		}

		return await apiCall(`${process.env.REACT_APP_IPC_HOST}/DOMSMicroservices/api/v1.0/ReadPrices`, Request, { hideError })
	}

	return {
		loginAPI,
		logoutAPI,
		launchDeviceSellAPI,
		fetchPrices
	}
}

export default useAuthService

import { useContext } from 'react'
import { useHistory, Link } from 'react-router-dom'
import AuthContext from '../contexts/authContext'
import useAppModel from '../contexts/AppModel'
import useAlertModel from '../contexts/AlertModel'
import useAuthService from '../services/apiServices/useAuthService'
import FuelContext from '../contexts/fuelContext'
import { PUMP_TYPE, FUEL_CODE } from '../constants'
import { LOCALSTORAGE_KEY, setItem } from '../services/storageService'
import { clubArray } from '../utills'
import routePath from '../route'

import { useLogin } from '../hooks'
import QuickPrintButton from '../FuelSale/QuickPrintButton'
import ViewSaleButton from '../FuelSale/ViewSaleButton'
import route from '../route'
import logoImg from '../img/logo.png'

const FixedHeader = () => {
	const history = useHistory()
	const { onLogout } = useLogin()

	const {
		authState: {
			objSupervisorLogin: { Circle_Response: { org: storeId } = {}, Store_State, Store_Name } = {},
			objGetStore: { POSID } = {},
			userId,
			objDeviceSave: { Msg: guid } = {},
		},
	} = useContext(AuthContext)

	const isOffline = useAppModel(s => s.isOffline)
	const prices = useAppModel(s => s.prices)
	const setPrice = useAppModel(s => s.setPrice)
	const showLoader = useAppModel(s => s.showLoader)
	const hideLoader = useAppModel(s => s.hideLoader)

	const { fetchPrices } = useAuthService()
	const {
		fuelState: { keepAlive, } = {},
		setFuelInput,
		setCurrentTxnPrice,
		setWidget
	} = useContext(FuelContext)

	const pCode = FUEL_CODE[12349]
	const doAlert = useAlertModel(s => s.doAlert)

	const LogOut = async () => {
		doAlert({
			message: 'Are you sure you want to Log Out?',
			buttons: [
				{ label: 'Cancel', },
				{ label: 'Yes', onClick: () => onLogout(), },
			],
		})
	}

	const gotoEcomSale = async () => {
		if (keepAlive) {
			return
		}

		await callFetchPrice(false)
		setFuelInput({ fuelType: PUMP_TYPE[pCode], selectedType: pCode })
		setCurrentTxnPrice(prices['HSD'])
		setWidget("ECOM_SALE")
		setItem(LOCALSTORAGE_KEY.pCode, pCode, true)

		history.push(routePath.fuelSale.path)
		return true
	}

	const callFetchPrice = async (hasLoader = true) => {
		if (guid && POSID) {
			try {
				if (! hasLoader) {
					showLoader('Fetching prices...')
				}
				const priceRes = await fetchPrices(hasLoader)
				setPrice(clubArray(priceRes?.FcPrices?.FcGradeName, priceRes?.FcPrices?.FcPriceGroups[0]))
			} catch (error) {
				console.error(error)
			} finally {
				if (! hasLoader) {
					hideLoader()
				}
			}
		} else {
			doAlert({
				message: 'Something wrong, please re-launch the application',
				buttons: [{ label: "Ok" }]
			})
		}
	}

	const toHome = () => history.push(route.dashboard.path)
	const updateApp = () => {
		window.location.href = route.dashboard.path
	}

	return (
		<>
			<div className="header-container">
				<div className="header-item">
					<div className="logo">
						<Link to={route.dashboard.path}>
							<img alt="Jio BP" src={logoImg} />
						</Link>
					</div>
					<h3>
						{Store_Name || Store_State}
						<span>Store ID - {storeId} | POS ID - {POSID}</span>
					</h3>
				</div>
				<div className="head-uid">
					<h3 className="bold">{isOffline ? 'OFFLINE' : 'ONLINE'}</h3>
				</div>
				<div className="head-uid">
					<h3>UID - {userId} </h3>
					<span className="version" onClick={updateApp}>
						{`Version ${process.env.REACT_APP_VERSION}`}
					</span>
				</div>
			</div>

			<div className="side-menu">
				<ul>
					<li>
						<button className="side-menu-item" onClick={toHome}>
							<h3 className="icon-menu-home" />
							<span>Home</span>
						</button>
					</li>

					<li><ViewSaleButton /></li>
					<li><QuickPrintButton /></li>

					<li>
						<button className={`side-menu-item `} onClick={gotoEcomSale}>
							<h3 className="icon-menu-sale" />
							<span>ECOM Sale</span>
						</button>
					</li>

					<li>
						<button className="side-menu-item" onClick={LogOut}>
							<h3 className="icon-menu-logout" />
							<span>Log Out</span>
						</button>
					</li>
				</ul>
			</div>
		</>
	)
}
export default FixedHeader

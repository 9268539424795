import { useRef, useEffect, useContext, useState } from 'react'
import findKey from 'lodash/findKey'
import FuelContext from '../../contexts/fuelContext'
import useFuelService from '../../services/apiServices/useFuelService'
import HomeFuelingContext from '../context/homeFuelingContext'
import { useLogin, useScheduler } from '../../hooks'
import FuelPointItem from './FuelPointItem'
import useAlertModel from '../../contexts/AlertModel'

function NozzleStatus() {
	const { fetchFpStatusAll } = useFuelService()
	const [finalFpStatus, setfinalFpStatus] = useState([])
	const FpIDs = useRef([])
	const scheduler = useScheduler()
	const {
		fuelState: { nozzleLst },
	} = useContext(FuelContext)
	const { setContextCurrentFueling } = useContext(HomeFuelingContext)
	const doAlert = useAlertModel(s => s.doAlert)
	const { onLogout } = useLogin()

	useEffect(() => {
		const fetchFpStatus = async () => {
			try {
				Object.keys(nozzleLst).forEach(prdCode => {
					const lstFpIds = nozzleLst[prdCode]
					lstFpIds.forEach(Id => {
						const numFpId = parseInt(Id.FpId)
						if (!FpIDs.current.includes(numFpId)) {
							FpIDs.current.push(numFpId)
						}
					})
				})
			} catch (error) {
				console.error(error)
			}
		}
		const fetchNozzleStatus = async () => {
			try {
				const { lstFpStatus = [] } = await fetchFpStatusAll(true)
				const finalFpStatus = []

				const fpOpenList = lstFpStatus.filter(
					item => FpIDs.current.includes(parseInt(item.FpId, 10)) && !['01H', '02H'].includes(item.FpMainState.enum.Closed)
				)

				if (fpOpenList.length === 0) {
					scheduler.pause()
					doAlert({
						message: 'No pumps assigned to you',
						buttons: [
							{ label: 'Login again', onClick: onLogout },
						],
					})
				}

				let currentFueling = null
				lstFpStatus.forEach(item => {
					const {
						FpId,
						FpMainState: { enum: fpSates = {} } = {},
						FpSupplStatusPars: { FuellingDataMon_e, FuellingDataVol_e, } = {},
					} = item

					if (FpIDs.current.includes(parseInt(item.FpId))) {
						const statusKey = findKey(fpSates, o => o !== null)
						finalFpStatus.push({ FpID: FpId, Mon_e: FuellingDataMon_e, Vol_e: FuellingDataVol_e, status: statusKey, })

						if (statusKey === 'Fuelling' || statusKey === 'Starting') {
							currentFueling = { FpID: FpId, Mon_e: FuellingDataMon_e, Vol_e: FuellingDataVol_e, status: statusKey, }
						}
					}
				})
				setfinalFpStatus(finalFpStatus)

				setContextCurrentFueling(currentFueling)
			} catch (error) {
				console.error(error)
			}
		}
		scheduler.subscribe(fetchNozzleStatus, 3000)
		fetchFpStatus()

		return () => {
			scheduler.unSubscribe()
		}
	}, [])

	return (
		<div className="card info mb-4">
			{finalFpStatus.map(item => (
				<FuelPointItem key={`fp_status_${item.FpID}`} data={item} />
			))}
		</div>
	)
}

export default NozzleStatus

import { encode } from './utills/base64'

const getValues = userId => {
	const strUserId = `${userId}`
	let res = ''
	for (let i = 0; i < strUserId.length; ++i) {
		const charCode = strUserId.charCodeAt(i)

		if (48 <= charCode && charCode <= 56) {
			res += String.fromCharCode(charCode + 1)
		} else {
			res += strUserId[i]
		}
	}
	return res
}

export const basicAuth = userId => encode(userId + ':' + getValues(userId))

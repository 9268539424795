const keepLogin = true

const realStorage = window.localStorage
let myStorage = null

const mockLocalStorage = () => {
	let store = {}
	const getItem = (key) => {
		return key in store ? store[key] : null
	}
	const setItem = (key, value) => {
		store[key] = `${value}`
	}
	const removeItem = (key) => {
		delete store[key]
	}
	const clear = () => {
		store = {}
	}

	return { getItem, setItem, removeItem, clear }
}

if (keepLogin) {
	myStorage = window.localStorage
} else {
	myStorage = mockLocalStorage()
}


const LOCALSTORAGE_KEY = {
	authToken: 'FUEL_AUTH_TOKEN',
	userId: 'FUEL_USER_ID',
	password: 'FUEL_PASSWORD',
	storeId: 'FUEL_STORE_ID',
	guid: 'FUEL_GUID',
	appConfig: 'FUEL_APP_CONFIG',
	loginTime: 'FUEL_LOGIN_TIME',
	txnData: 'TXN_DATA',
	macId: 'MAC_ID',
	pCode: 'pCode'
}

const setItem = (key, value, isReal = false) => {
	if (isReal) {
		realStorage.setItem(key, value)
	} else {
		myStorage.setItem(key, value)
	}
}

const getItem = (key, isReal = false) => {
	if (isReal) {
		return realStorage.getItem(key)
	} else {
		return myStorage.getItem(key)
	}
}

const removeItem = (key, isReal = false) => {
	if (isReal) {
		return realStorage.removeItem(key)
	} else {
		return myStorage.removeItem(key)
	}
}

const clear = () => {
	Object.values(LOCALSTORAGE_KEY).forEach(item => {
		if (item !== LOCALSTORAGE_KEY.macId && item !== LOCALSTORAGE_KEY.txnData) {
			myStorage.removeItem(item)
		}
	})
}

export { LOCALSTORAGE_KEY, setItem, getItem, removeItem, clear as storageClear }

import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import StyledModal from '../common/StyledModal'
import QuickPrintModal from './QuickPrintModal'
import route from '../route'

function ViewSaleButton() {
	const [isOpen, setIsOpen] = useState(false)
	const history = useHistory()

	const handlePrint = FpID => {
		setIsOpen(false)
		history.push(route.report.path, { FpID })
	}
	const closeModal = () => {
		setIsOpen(false)
	}
	const openModal = () => {
		setIsOpen(true)
	}
	return (
		<>
			<button style={{ "background": "center" }} className="side-menu-item" type="button" onClick={openModal}>
				<h3 className="icon-menu-sale" />
				<span>Sale</span>
			</button>

			<StyledModal title="Pump No" open={isOpen} onClose={closeModal}>
				<QuickPrintModal closeModal={closeModal} onSubmit={handlePrint} />
			</StyledModal>
		</>
	)
}

export default ViewSaleButton

import { useLogin } from '../hooks'
import './login.css'
import logo from '../img/login-logo.png'

const Login = () => {
	const { userId, setUserId, password, setPassword, onLogin } = useLogin()

	return (
		<div className="login-container d-flex">
			<div className="login-bg" />
			<div className="login-form-container">
				<div className="login-logo">
					<img alt="Jio BP" src={logo} />
				</div>
				<p>Welcome back! Please login to your account</p>
				<form onSubmit={onLogin}>
					<div className="input-group theme-grp-btn">
						<input placeholder="Username" autoFocus type="text" className="form-control" value={userId} onChange={e => setUserId(e.target.value)} required />
					</div>
					<div className="input-group theme-grp-btn mt-2">
						<input placeholder="Password" type="password" className="form-control" value={password} onChange={e => setPassword(e.target.value)} required />
					</div>
					<div className="d-flex justify-content-center mt-3">
						<button type="submit" className="btn btn-lg btn-secondary login-btn">Login</button>
					</div>
				</form>
				<small className="mt-3">Version: {process.env.REACT_APP_VERSION}</small>
			</div>
		</div>
	)
}

export default Login

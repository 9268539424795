export function getMacId() {
	return new Promise((resolve, reject) => {
		window.setMacAddress = (macIdValue) => {
			if (typeof resolve === 'function') {
				if (macIdValue) {
					resolve(macIdValue)
				} else {
					reject('Unable to get macId')
				}
			}

			window.setMacAddress = undefined
		}

		if (window.Android) {
			window.Android.getMacAddress()
		}
	})
}

import { useContext, useEffect } from 'react'
import AuthContext from '../contexts/authContext'
import useAppModel from '../contexts/AppModel'
import useAlertModel from '../contexts/AlertModel'
import useAuthService from '../services/apiServices/useAuthService'
import { clubArray } from '../utills'
import { useScheduler } from '../hooks'
import FuelWidgetItem from './FuelWidgetItem'

function FuelWidget() {
	const {
		authState: {
			objDeviceSave: { Msg: guid } = {},
			objGetStore: { POSID } = {},
			lstFuelProducts = []
		}
	} = useContext(AuthContext)
	const setPrice = useAppModel(s => s.setPrice)
	const showLoader = useAppModel(s => s.showLoader)
	const hideLoader = useAppModel(s => s.hideLoader)
	const { fetchPrices } = useAuthService()
	const doAlert = useAlertModel(s => s.doAlert)
	const scheduler = useScheduler()

	useEffect(() => {
		scheduler.subscribe(callFetchPrice, 300000, [[false], [true]])
		return () => {
			scheduler.unSubscribe()
		}
	}, [guid, POSID])

	const callFetchPrice = async (hasLoader = true) => {
		if (guid && POSID) {
			try {
				if (! hasLoader) {
					showLoader('Fetching prices...')
				}
				const priceRes = await fetchPrices(hideLoader)
				setPrice(clubArray(priceRes?.FcPrices?.FcGradeName, priceRes?.FcPrices?.FcPriceGroups[0]))
			} catch (error) {
				console.error(error)
			} finally {
				if (! hasLoader) {
					hideLoader()
				}
			}
		} else {
			doAlert({
				message: 'Something wrong, please re-launch the application',
				buttons: [{ label: "Ok" }]
			})
		}
	}

	return (
		<div className="fuel">
			<div className="d-flex flex-row fuel-box">
				{lstFuelProducts.map(productItem => <FuelWidgetItem key={productItem.ProductID} productItem={productItem} priceFetch={callFetchPrice} />)}
				<FuelWidgetItem key={12349} productItem={{ "ProductName": "ECOM", "ProductID": 12349 }} priceFetch={callFetchPrice} />
			</div>
		</div>
	)
}

export default FuelWidget;



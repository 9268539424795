import { useRef, useEffect } from 'react'
import { createPortal } from 'react-dom'

function createPortalElement() {
	if (! document.getElementById('hookModalPortal')) {
		const newElement = document.createElement('div')
		newElement.id = 'hookModalPortal'
		const body = document.getElementsByTagName('body')[0]
		body.appendChild(newElement)
	}
}

function Modal({ open, children, onClose }) {
	createPortalElement()
	const modalRef = useRef()

	useEffect(() => {
		let handler = e => {
			if (modalRef.current === e.target) {
				onClose()
			}
		}
		document.addEventListener('mousedown', handler)

		return () => {
			document.removeEventListener('mousedown', handler)
		}
	}, [])

	return createPortal(
		<>
			{open ? (
				<div className="modal-container" ref={modalRef}>
					{children}
				</div>
			) : null}
		</>,
		document.getElementById('hookModalPortal')
	)
}
export default Modal

import { useState, useEffect, useContext, useRef } from 'react'
import isEmpty from 'lodash/isEmpty'
import findKey from 'lodash/findKey'
import dayjs from 'dayjs'
import Spinner from '../common/Spinner'
import AuthContext from '../contexts/authContext'
import useAppModel from '../contexts/AppModel'
import FuelContext from '../contexts/fuelContext'
import useReportService from '../services/apiServices/useReportService'
import useFuelService from '../services/apiServices/useFuelService'
import ReceiptModal from './ReceiptModal'
import receiptPrint from '../utills/receiptPrint'
import StyledModal from '../common/StyledModal'
import { FUEL_ID, PRODUCT_RECEIPT_NAME, PRODUCT_CODE, MOP_ID, FUEL_TYPE } from '../constants'
import route from '../route'
import { useHistory } from 'react-router-dom'
import isToday from 'dayjs/plugin/isToday'
dayjs.extend(isToday)

function PrintReceipt({ TxnId, FpID, TxnStartTime, DeviceID, isOpen, onRequestClose, printData = null, allowInputs = false, saveInvoice = false, }) {
	const invoiceText = useRef('')
	const [data, setData] = useState(printData !== null ? printData : {})
	const [apiRes, setApiRes] = useState(null)
	const [error, setError] = useState('')
	const [isInputOpen, setIsInputOpen] = useState(false)
	const showLoader = useAppModel(s => s.showLoader)
	const hideLoader = useAppModel(s => s.hideLoader)
	const { fetchTrxn, fpQuickPrint } = useReportService()
	const { updateInvoiceDetails: updateInvoiceDetailsApi, saveInvoiceApi, } = useFuelService()

	const {
		authState: {
			objSupervisorLogin: { Circle_Response: { org: storeId } = {}},
			objGetStore: { Headerlines, Footerlines, lstHeaderLines = [], lstFooterLines = [], } = {},
		} = {},
	} = useContext(AuthContext)

	const {
		fuelState: { keepAlive = false, } = {},
		setCurrentStep, setWidget, setFuelInvoiceData, setFuelInput, setCurrentTxnPrice,
	} = useContext(FuelContext)

	const history = useHistory()

	const getReceiptText = (data) => {
		invoiceText.current = receiptPrint(data, Headerlines, Footerlines, lstHeaderLines, lstFooterLines)
		if (saveInvoice) {
			try {
				saveInvoiceApi(invoiceText.current.replaceAll('[L]', ''), TxnId)
			} catch (e) {
				//
			}
		}
	}

	useEffect(() => {
		const FetchData = async () => {
			try {
				let res = ''

				if (TxnId) {
					res = await fetchTrxn({ TxnId, FpID, TxnStartTime, DeviceID })
				} else {
					res = await fpQuickPrint({ FpID })
				}

				const TransJson = JSON.parse(res.TransJson)
				setApiRes(TransJson)

				const {
					TxnInfo: {
						TxnHeader: { TaxInvoice, TxnTotal: totalAmt, TxnEndTime, UserId, ContactNumber, OrderType, ESOrderID: orderId, TxnStartTime: txnStartTime, TxnId: TxnID, BalanceLoyaltyPoints, LoyaltyCardNumber, },
						TxnFuelPointList: [{ VEHICLE_NO: vehicleNo, FPID, FpGradeOptionNo }],
						TxnItemList: [{ OrgPrice: fuelPrice, Quantity: totalVol, ProductID }],
						TxnTenderList: [{ AuthCode: authCode, CardNumber: cardNo, Description: mopValue, MOPID: mopId, TypeDescription: fleetMode, MobileNo: tenderMobileNo, TransRefNo: refId }],
					},
					StoreNo
				} = TransJson

				const fuelType = findKey(FUEL_ID, o => o === ProductID)
				const selectedType = OrderType.replace('ECOM', '').replace('_', '')
				const invoiceData = {
					storeCode: storeId, TxnId: TxnID,
					isECOM: selectedType !== '', orderId: orderId || '',
					StoreNo, FPID, FpGradeOptionNo, TaxInvoice, TxnStartTime: txnStartTime, TxnEndTime, productCode: PRODUCT_CODE[selectedType] || '', fuelType: PRODUCT_RECEIPT_NAME[selectedType !== '' ? OrderType : fuelType],
					totalVol, fuelPrice, totalAmt, mopValue, cardNo, authCode, mopId, fleetCard: cardNo, fleetMode, tenderMobileNo, refId,
					ContactNumber, vehicleNo, dsmId: `${UserId}`.slice(-2),
					time: dayjs().format('ddd, DD MMM YYYY HH:mm:ss'),
					rewardMeter: { points: OrderType === 'DLP' ? BalanceLoyaltyPoints : null, mobile: LoyaltyCardNumber },
				}
				setData(invoiceData)
				getReceiptText(invoiceData)
			} catch (error) {
				setError(error.ErrorMsg || error.Errormsg || error.errormsg)
			}
		}

		if (FpID && isEmpty(printData)) {
			FetchData()
		}
	}, [TxnId, FpID])

	useEffect(() => {
		if (printData !== null) {
			setData(printData)
			getReceiptText(printData)
		}
	}, [printData])

	const printDiv = () => {
		if (window.Android) {
			if (invoiceText.current === '') {
				getReceiptText(data)
			}

			window.Android.print(invoiceText.current)
		} else {
			const divToPrint = document.getElementById('Receipt').innerHTML
			const newWin = window.open('', 'Print-Window')
			newWin.document.open()
			newWin.document.write(`<html><body onload="window.print()">${divToPrint}</body></html>`)
			newWin.document.close()
			setTimeout(function () {
				newWin.close()
			}, 10)
		}
	}

	const closeModal = () => {
		onRequestClose()
		setError('')
	}

	const disabled = isEmpty(data)

	const updateContactNo = e => {
		setData({ ...data, ContactNumber: e.target.value })
	}

	const updateVehicleNo = e => {
		setData({ ...data, vehicleNo: e.target.value })
	}

	const updateInvoiceDetails = async () => {
		try {
			showLoader('Updating details...')
			getReceiptText(data)
			await updateInvoiceDetailsApi({
				...apiRes,
				TxnInfo: {
					...apiRes.TxnInfo,
					TxnHeader: {
						...apiRes.TxnInfo.TxnHeader,
						ContactNumber: data.ContactNumber,
					},
					TxnFuelPointList: apiRes.TxnInfo.TxnFuelPointList.map(item => ({
						...item,
						VEHICLE_NO: data.vehicleNo,
					}))
				}
			})
		} catch (e) {
			//
		} finally {
			hideLoader()
		}
		setIsInputOpen(false)
	}

	const handleMOPChange = () => {
		const tmpFuelType = findKey(FUEL_TYPE, o => o === data.fuelType)
		if (apiRes.TxnInfo.TxnHeader.LoyaltyCardNumber) {
			setFuelInput({ rewardMeter: { mobile: apiRes.TxnInfo.TxnHeader.LoyaltyCardNumber, points: apiRes.TxnInfo.TxnHeader.BalanceLoyaltyPoints } })
		}
		setFuelInput({ pumpValue: data.FPID, mopValue: `${MOP_ID['cash']}`, fuelType: tmpFuelType, })
		setFuelInvoiceData({ totalAmt: data.totalAmt, totalVol: data.totalVol, TaxInvoice: data.TaxInvoice, txnStatus: 'COMPLETED', TxnStartTime: data.TxnStartTime, TxnID: data.TxnId, })
		setFuelInput({ uploaded: apiRes, isUploaded: true, })
		const fuelPointList = apiRes.TxnInfo.TxnFuelPointList[0]
		setFuelInvoiceData({
			saleOk: {
				TransSeqNo: fuelPointList.DOMSTransSeqNo,
				StartTotalizer: fuelPointList.StartTotalizer,
				EndTotalizer: fuelPointList.EndTotalizer,
				FuelStartTime: fuelPointList.FuelStartTime,
				FuelEndTime: fuelPointList.FuelEndTime,
				FpGradeOptionNo: fuelPointList.FpGradeOptionNo,
			}
		})

		setWidget(tmpFuelType)
		setCurrentTxnPrice(data.fuelPrice)
		setCurrentStep('Summery')
		history.push(route.fuelSale.path)
	}

	if (error) {
		return (
			<ModalWrapper isOpen={isOpen} onClose={closeModal} printDiv={null} disabled={true} allowInputs={false}>
				<p className="mt-3 text-center text-danger">{error}</p>
			</ModalWrapper>
		)
	}

	if (disabled) {
		return (
			<ModalWrapper isOpen={isOpen} onClose={closeModal} printDiv={null} disabled={true} allowInputs={false}>
				<div className="mt-5 d-flex justify-content-center">
					<Spinner />
				</div>
			</ModalWrapper>
		)
	}

	const txnHour = dayjs(data.TxnStartTime, 'DD/MM/YYYY hh:mm:ss').hour()
	const txnDate = parseInt(dayjs(data.TxnStartTime, 'DD/MM/YYYY hh:mm:ss').format('MDD'), 10)
	const currentHour = dayjs().hour()
	const yesterdayDate = parseInt(dayjs().subtract(1, 'day').format('MDD'), 10)
	const allowInputsDate = currentHour < 9
		? (txnHour <= 9 && txnDate > yesterdayDate) || (txnHour >= 6 && txnDate === yesterdayDate)
		: txnHour >= 6 && txnDate > yesterdayDate

	return (<>
		<ModalWrapper
			isOpen={isOpen && !isInputOpen}
			onClose={closeModal}
			printDiv={printDiv}
			disabled={disabled || data.mopId === `${MOP_ID['sap']}`}
			allowInputs={allowInputs && !data.isECOM && data.mopId === `${MOP_ID['cash']}` && allowInputsDate}
			openInputModal={() => setIsInputOpen(true)} handleMOPChange={handleMOPChange}
		>
			<ReceiptModal data={data} />
		</ModalWrapper>
		<StyledModal title="Enter details" open={isInputOpen} onClose={() => setIsInputOpen(false)}>
			<h5>Vehicle Number:</h5>
			<div className="input-group theme-grp-btn">
				<input autoFocus type="text" className="form-control" value={data.vehicleNo} onChange={updateVehicleNo} required maxLength="10" />
			</div>
			<br />
			<h5>Mobile Number:</h5>
			<div className="input-group theme-grp-btn">
				<input inputMode="decimal" type="text" className="form-control" value={data.ContactNumber} onChange={updateContactNo} required maxLength="10" />
			</div>
			<button onClick={updateInvoiceDetails} type="button" className="btn btn-lg btn-success mt-3">Update</button>
		</StyledModal>
	</>)
}

const ModalWrapper = ({ children, isOpen, onClose, printDiv, disabled, allowInputs, openInputModal, handleMOPChange }) => (
	<StyledModal title="Receipt" open={isOpen} onClose={onClose}>
		<div id="Receipt" className="receipt-container">
			{children}
		</div>
		{allowInputs && (
			<div className="btn-group w-100 mt-3">
				<button onClick={handleMOPChange} type="button" className="btn btn-lg btn-outline-success">
					<i className="fas fa-credit-card mr-2"></i> Change MOP
				</button>
				<button onClick={openInputModal} type="button" className="btn btn-lg btn-outline-success">
					<i className="fas fa-car mr-2"></i> Input Details
				</button>
			</div>
		)}
		<button disabled={disabled} onClick={e => printDiv(e)} type="button" className="btn btn-lg btn-outline-success mt-3">
			<i className="fas fa-print mr-2"></i> Print
		</button>
	</StyledModal>
)

export default PrintReceipt

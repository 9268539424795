export const apiTimeOut = 90000

export function numcheck(e) {
	const re = /^[0-9\b]+$/
	if (e !== '' && re.test(e)) {
		return true
	} else if (e !== '') {
		return false
	}
}

//added by cc
export function getHttpStatus(code) {
	switch (code) {
		case '100':
			return 'Continue'

		case '304':
			return 'Not Modified'

		case '305':
			return 'Use Proxy'

		case '400':
			return 'Bad Request'

		case '401':
			return 'Session Expired'

		case '403':
			return 'Forbidden'

		case '404':
			return 'Not Found'

		case '408':
			return 'Request Timeout'

		case '407':
			return 'Proxy Authentication Required'

		case '409':
			return 'Conflict'

		case '500':
			return 'Internal Server Error'

		case '501':
			return 'Not Implemented'

		case '502':
			return 'Bad Gateway'

		case '503':
			return 'Service Unavailable'

		case '504':
			return 'Gateway Timeout'

		case '505':
			return 'HTTP Version Not Supported'

		default:
			return 'Some Error occurred. Please try again'
	}
}

export function getErrorMsgCode(msg1) {
	let msg = msg1.toString()
	if (msg.search('400') !== -1) {
		return '400'
	} else if (msg.search('401') !== -1) {
		return '401'
	} else if (msg.search('408') !== -1) {
		return '408'
	} else if (msg.search('500') !== -1) {
		return '500'
	} else if (msg.search('502') !== -1) {
		return '502'
	} else if (msg.search('503') !== -1) {
		return '503'
	} else if (msg.search('504') !== -1) {
		return '504'
	} else if (msg.search('Network Error') !== -1) {
		return '1001'
	} else {
		return '1000'
	}
}

export const VERSION = '5.3.4'

export const FUEL_CODE = {
	12346: 'MS',
	12345: 'HSD',
	12347: 'ALPG',
	12349: 'ECOM'
}

export const FUEL_ID = {
	MS: '12346',
	HSD: '12345',
	ALPG: '12347',
}

export const FUEL_TYPE = {
	MS: 'Petrol',
	HSD: 'Diesel',
	ALPG: 'ALPG',
	PFCJIO: 'PFCJIO',
}

export const PRODUCT_RECEIPT_NAME = {
	MS: 'Petrol',
	ALPG: 'ALPG',
	HSD: 'Diesel',
	ECOM_PFC: 'Diesel in returnable container',
	ECOM_MDU: 'HSD MDU',
	ECOMPFCJIO: 'HSDBSVI-Non transport segment',
	SAP_MDU: 'HSD MDU JIO',
}

export const PRODUCT_CODE = {
	MS: 4,
	HSD: 1,
	ALPG: 1001083,
	PFC: 29,
	MDU: 32,
	PFCJIO: 48,
	MDUJIO: 32,
}

export const MOP_LIST = {
	1: 'cash',
	2: 'credit',
	22: '',
	27: 'fleet',
	242: 'fleet',
	223: 'pump',
	224: 'own-use',
	225: 'voucher',
	226: 'dealer-cr',
	244: 'paytm',
	245: 'paytm',
	319: 'clm-voucher',
	326: 'voucher-redemption',
	342: 'ecom',
	356: 'sap'
}

export const FUEL_MOP_DISABLE = {
	MS: [342, 356, ],
	HSD: [342, 356, ],
	ALPG: [27, 342, 356, ],
	MDU: [223, 224, 326, 319, ],
	PFC: [223, 224, 326, 319, ],
	PFCJIO: Object.keys(MOP_LIST).filter(i => !['27', '242', '223', '342'].includes(i)).map(i => parseInt(i, 10)),
}

export const CHANGE_MOP_DISABLE = [
	27,
]

export const CHANGE_MOP_DISABLE_FLEET = [
	2, 22, 27, 223, 224, 244, 245,
]

export const MOP_ID = {
	clmVoucher: 319,
	voucherRedemption: 326,
	pumpTest: 223,
	ownuse: 224,
	ecom: 342,
	sap: 356,
	paytm: 244,
	offlinePaytm: 245,
	cash: 1,
	credit: 2,
	fleet: 27,
}

export const PUMP_TYPE = {
	MS: 'MS',
	HSD: 'HSD',
	ALPG: 'ALPG',
	PFC: 'HSD',
	MDU: 'HSD',
	ECOM: 'HSD',
	PFCJIO: 'HSD',
}

export const ECOM_PUMP_TYPE = {
	HSDBS4P20: 'PFC',
	HSDBS4MDU: 'MDU',
	HSDBS6MDU: 'MDU',
}

export const FUEL_VOLUME_UNIT = {
	topup: 'topup',
	volume: 'Volume',
	money: 'Money',
}

export const PRODUCT_TYPE = {
	12346: 'Petrol',
	12345: 'Diesel',
	12347: 'ALPG',
}

export const INVOICE_FLEET_FIELD = {
	S: 'Swipe:',
	M: 'Mobile No:',
	I: 'Mobile No:',
	// V: 'Vehicle No:',
}

export const PRODUCT_ISSUER_ID = {
	PFCJIO: ['100011', '100012', '100014', '100016'],
}

export const CURRENT_TXN = {
	currentTxn: {
		Identifier: '',
		TempID: '',
		fuelInputes: { selectedType: '', fuelType: '', pumpValue: '', nozzleValue: '', loyalty: false, mopValue: '', fuelBy: '', fuelByValue: '', fleetMode: '', fleetCard: '', fleetPrompts: {}, vehicleNo: '', ecomOrderType: '', MDUSelectedOrder: '', cardNo: '', rrnNo: '', pumpMode: '', mobileNo: '' },
		fuelinvoiceData: { TxnStartTime: '', TxnEndTime: '', totalAmt: '0', totalVol: '0', TxnID: '', BillOfSupply: '', TaxInvoice: '', ARV: '', SecurityDepositReceipt: '', ListTax: [], saleOk: {}, txnStatus: '', },
		fuelingStarted: false,
		tcfaICRes: {},
		currentTxnPrice: '',
	},
}

export const GRADE_ID = {
	10: 'MS',
	20: 'HSD',
	30: 'ALPG',
}

export const GRADE_CODE = {
	'MS': 10,
	'HSD': 20,
	'ALPG': 30,
}

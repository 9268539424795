import { useState, createContext } from 'react'

const HomeFuelingContext = createContext()

const HomeFuelingProvider = ({ children }) => {
	const [contextCurrentFueling, setContextCurrentFueling] = useState(null)
	const [contextDisplayFueling, setContextDisplayFueling] = useState(false)

	return (
		<HomeFuelingContext.Provider value={{
			contextCurrentFueling, setContextCurrentFueling,
			contextDisplayFueling, setContextDisplayFueling,
		}}>
			{children}
		</HomeFuelingContext.Provider>
	)
}

export { HomeFuelingContext as default, HomeFuelingProvider }

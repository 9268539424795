import { useContext } from 'react'
import AuthContext from '../contexts/authContext'
import { INVOICE_FLEET_FIELD, MOP_ID } from '../constants'
import logoImg from '../img/logo.png'

function ReceiptModal({ data }) {
	const {
		authState: {
			objGetStore: { Headerlines, Footerlines, lstHeaderLines = [], lstFooterLines = [], },
		} = {},
	} = useContext(AuthContext)
	const {
		storeCode = '',
		isECOM, orderId, FPID, FpGradeOptionNo, TaxInvoice, totalAmt, totalVol, TxnStartTime, mopValue, vehicleNo, refId,
		cardNo, fleetCard, authCode, fuelPrice, productCode, fuelType, mopId, fleetMode, time, dsmId, ContactNumber,
		rewardMeter = { points: null, mobile: '' },
	} = data

	const totalAmtStr = parseFloat(totalAmt).toFixed(2)
	const totalVolStr = parseFloat(totalVol).toFixed(2)
	const fuelPriceStr = parseFloat(fuelPrice).toFixed(2)

	return (
		<div className="receiptmodal-container">
			<div className="receipt-row">
				<img src={logoImg} alt="" />
			</div>
			<div className="justify-content-center">
				<HeaderFooter lineList={Headerlines} lineObjList={lstHeaderLines} />
				<div className="receipt-row">RO ID: {storeCode}</div>
				<div className="receipt-row mt-2">Customer Copy</div>
			</div>
			<div className="receipt-row-item mt-3">Inv: {TaxInvoice}</div>
			<div>Date: {TxnStartTime}</div>
			{[MOP_ID['clmVoucher'], MOP_ID['voucherRedemption']].includes(parseInt(mopId, 10)) && (
				<div>Voucher No: {fleetCard}</div>
			)}
			{orderId !== '' && orderId[0] !== 'F' && (
				<div>Order no: {orderId}</div>
			)}
			{mopId === '27' && (<>
				<div>{INVOICE_FLEET_FIELD[fleetMode]} {fleetCard}</div>
				<div>Fleet invoice: {refId}</div>
				<div>Auth Code: {authCode}</div>
			</>)}
			{mopId === '2' && (<>
				<div>Card No: {cardNo}</div>
				<div>Auth Code: {authCode}</div>
			</>)}
			{[MOP_ID['clmVoucher'], MOP_ID['voucherRedemption']].includes(parseInt(mopId, 10)) && (
				<div>Voucher No: {fleetCard}</div>
			)}
			{[MOP_ID['paytm'], MOP_ID['offlinePaytm']].includes(parseInt(mopId, 10)) && (<>
				<div>Card No: {cardNo}</div>
				<div>RRN No: {refId}</div>
			</>)}
			{![MOP_ID['pumpTest'], MOP_ID['ownuse']].includes(parseInt(mopId, 10)) && (<>
				{ContactNumber !== '' && !(mopId === '27' && ['M', 'I'].includes(fleetMode)) && <div>Mobile No: {ContactNumber}</div>}
				{vehicleNo !== '' && <div>Vehicle No: {vehicleNo}</div>}
			</>)}
			<div className="row mt-3 item-bordertop">
				<div className="col-2">Item</div>
				<div className="col-2">Qty</div>
				<div className="col-2">UoM</div>
				<div className="col-2">Rate(Rs)</div>
				<div style={{ textAlign: 'right' }} className="col-4">Amt(Rs)</div>
			</div>

			{isECOM && (
				<div className="row">
					<div className="col-12">{fuelType}</div>
				</div>
			)}
			<div className="row item-borderbottom">
				<div className="col-2">{isECOM ? productCode : fuelType}</div>
				<div className="col-2">{totalVolStr}</div>
				<div className="col-2">Ltr</div>
				<div className="col-2">{fuelPriceStr}</div>
				<div style={{ textAlign: 'right' }} className="col-4">{totalAmtStr}</div>
			</div>

			<div className="receipt-row-item">
				<span>Total Invoice Amount:</span>
				<span>Rs. {totalAmtStr}</span>
			</div>
			<div className="receipt-row-item mt-3">
				<span>Method of Payment:</span>
				<span>{mopValue}</span>
			</div>
			<div className="receipt-row-item">
				<span>Amount Tendered:</span>
				<span>Rs. {totalAmtStr}</span>
			</div>
			{rewardMeter.points !== null && (<>
				<div className="receipt-row-item">
					<span>Loyalty Card / Mob:</span>
					<span>{rewardMeter.mobile}</span>
				</div>
				<div className="receipt-row-item">
					<span>REWARDMETER Loyalty Point(s):</span>
					<span>{rewardMeter.points}</span>
				</div>
			</>)}
			<div className="item-bordertop">
				<HeaderFooter lineList={Footerlines} lineObjList={lstFooterLines} />
			</div>

			<div className="mt-1">
				{`FP-${FPID}-${FpGradeOptionNo}/DSM-${dsmId} ${time}`}
			</div>
		</div>
	)
}

const HeaderFooter = ({ lineList = [], lineObjList = [] }) => {
	if (lineObjList.length !== 0) {
		return (<>
			{lineObjList.map((element, key) => (
				<div key={`ho-${key}`} className={element?.IS_CENTER === '1' ? 'receipt-row' : ''}>{element.LINE_TEXT}</div>
			))}
		</>)
	}

	return (<>
		{lineList.map((element, key) => (<div key={`h-${key}`}>{element}</div>))}
	</>)
}

export default ReceiptModal

import { BrowserRouter, Switch } from 'react-router-dom'
import { AuthProvider } from './contexts/authContext'
import Login from "./Login/Login"
import MainContent from './MainContent'
import PrivateRoute from './PrivateRoute'
import AppSync from './AppSync'
import ConfirmBox from './common/ConfirmBox'
import FullPageLoader from './common/FullPageLoader'
import routePath from './route'
import './css/react-confirm-alert.css'

const App = () => (<>
	<AuthProvider>
		<BrowserRouter>
			<Switch>
				<PrivateRoute exact path={routePath.login.path} component={Login} />
				<PrivateRoute path="/" component={MainContent} />
			</Switch>
		</BrowserRouter>
		<AppSync />
	</AuthProvider>
	<ConfirmBox />
	<FullPageLoader />
</>)

export default App

import { useState, useEffect, useContext } from 'react'
import FuellingCard from '../FuelSale/FuellingCards'
import HomeFuelingContext from './context/homeFuelingContext'
import useAppModel from '../contexts/AppModel'
import useFuelService from '../services/apiServices/useFuelService'
import useObserver from '../hooks/useObserver'
import { GRADE_ID } from '../constants'

const FuelingCardWithFetch = ({ FpID }) => {
	const prices = useAppModel(s => s.prices)
	const [state, setState] = useState({
		totalVol: 0.00,
		totalAmt: 0.00,
		unitPrice: '0.00',
	})
	const { fuelFpStatus } = useFuelService()
	const observer = useObserver()

	const fetchFuellingStatus = async () => {
		try {
			const {
				FpStatus: {
					FcGradeId,
					FpSupplStatusPars: { FuellingDataMon_e, FuellingDataVol_e } = {},
				} = {},
			} = await fuelFpStatus({ pumpValue: FpID }, true)

			const FuellingUnitPrice = FcGradeId ? prices[GRADE_ID[FcGradeId]] : '0.00'
			setState({ totalVol: parseFloat(FuellingDataVol_e), totalAmt: parseFloat(FuellingDataMon_e), unitPrice: FuellingUnitPrice, })

			observer.notify()
		} catch (error) {
			console.error(error)
		}
	}

	useEffect(() => {
		observer.subscribe(fetchFuellingStatus)
		observer.notify()

		return () => {
			observer.unSubscribe()
		}
	}, [])

	return (
		<FuellingCard totalVol={state.totalVol} totalAmt={state.totalAmt} fuelBy="topup" price={state.unitPrice} />
	)
}

function FuelingStatusWidget() {
	const { contextCurrentFueling, contextDisplayFueling, setContextDisplayFueling, } = useContext(HomeFuelingContext)

	useEffect(() => {
		if (contextCurrentFueling) {
			setContextDisplayFueling(prev => !prev ? true : prev)
		} else {
			setContextDisplayFueling(false)
		}
	}, [contextCurrentFueling])

	return (
		<div className={`animate__animated ${contextDisplayFueling ? 'animate__fadeIn' : 'animate__fadeOut'}`}>
			{contextDisplayFueling && (
				<FuelingCardWithFetch FpID={contextCurrentFueling?.FpID} />
			)}
		</div>
	)
}

export default FuelingStatusWidget

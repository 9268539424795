import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import FuelContext from '../../contexts/fuelContext'
import routePath from '../../route'
import { getStatusCssClass } from '../../utills'
import route from '../../route'

function FuelPointItem({ data = {} }) {
	const history = useHistory()
	const {
		fuelState: {
			currentTxn: { fuelInputes: { pumpValue } = {} },
			keepAlive,
		} = {},
	} = useContext(FuelContext)

	const handleProcessTxn = async () => {
		if (keepAlive) {
			if (parseInt(pumpValue, 10) === parseInt(data.FpID, 10)) {
				history.push(routePath.fuelSale.path)
			}
			return false
		}
	}

	const nozzle_status = getStatusCssClass(data.status)
	return (
		<div onClick={handleProcessTxn} className="nozzle-card d-flex flex-row gap-3" role="presentation">
			<div className="d-flex flex-row mr-3" onClick={() => history.push(route.report.path, { FpID: data.FpID })}>
				<span className={`${nozzle_status.className} icon-nozzle icon-color`} />
				<div className="nozzle-block">
					<div className="circle">
						<h5>{data.FpID}</h5>
					</div>
					<strong>{data.status}</strong>
				</div>
			</div>

			<div className="d-flex flex-row ml-3">
				{keepAlive && parseInt(pumpValue, 10) === parseInt(data.FpID, 10) && (<>
					<div className="">
						<strong className="strongtext">Pending... </strong>
					</div>
					<i className="fas fa-angle-right"></i>
				</>)}
			</div>
		</div>
	)
}

export default FuelPointItem

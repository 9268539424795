import { create } from 'zustand'

const useAppModel = create((set, get) => ({
	isLoading: false,
	text: 'Loading...',
	counter: 0,
	hideLoader: () => {
		if (get().counter <= 1) {
			set(prev => ({ isLoading: false, text: 'Loading...', counter: prev.counter - 1 }))
		} else {
			set(prev => ({ counter: prev.counter - 1 }))
		}
	},
	showLoader: (text = 'Loading...') => {
		set(prev => ({ isLoading: true, text, counter: prev.counter + 1, }))
	},

	isOffline: false,
	setOfflineMode: mode => set({ isOffline: mode }),

	prices: {},
	setPrice: priceList => set(prev => ({ prices: { ...prev.prices, ...priceList } })),
}))

export default useAppModel

import FixedHeader from "../common/Header"
import FuelWidget from './FuelWidget.jsx'
import NozzleStatus from './NozzleStatus'
import FuelingStatusWidget from './FuelingStatusWidget'
import HomeRightCol from './HomeRightCol'
import { HomeFuelingProvider } from './context/homeFuelingContext'
import CancelCurrenttTxn from './CancelCurrentTxn'
import '../css/animate.css'

const Home = () => (
	<main>
		<FixedHeader />
		<div className="container-fluid">
			<div className="row">
				<FuelWidget />
			</div>
			<div className="row homenew-row">
				<HomeFuelingProvider>
					<div className="col-lg-4">
						<NozzleStatus />
					</div>
					<div className="col-lg-8 position-relative">
						<FuelingStatusWidget />
						<HomeRightCol />
					</div>
				</HomeFuelingProvider>
			</div>
		</div>
		<CancelCurrenttTxn />
	</main>
)

export default Home
